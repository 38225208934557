import './mira.css';
import {
    CloseOutlined,
    CloseSquareOutlined,
    CloudDownloadOutlined,
    CommentOutlined,
    DeleteOutlined,
    FileImageOutlined,
    FilePdfOutlined,
    InboxOutlined,
    LeftOutlined,
    LoadingOutlined,
    PlusOutlined,
    PlusSquareOutlined,
    SendOutlined
} from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import { Button, message, Modal, Popover, Spin, Switch } from 'antd';
import { agents } from 'caniuse-lite';
import ApiUrl from '../../network';
import normFile from '../../imageToBucket';
import Dragger from 'antd/es/upload/Dragger';
import dayjs from 'dayjs';

const miraURL = ApiUrl.MiraUrl;
const country = localStorage.getItem('country') || 'Ghana';
const baseUrl = `${miraURL}/api/v1/whatsapp`;
const socketUrl = `${miraURL}/`; // WebSocket endpoint

export const Index = () => {
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [socket, setSocket] = useState(null);
    const [messageType, setMessageType] = useState('text');
    const [imageUrl, setImageUrl] = useState('');
    const [fileList, setFileList] = useState({});
    const [replyTo, setReplyTo] = useState({
        _id: null,
        profile: null,
        content: null,
        whatsapp_id: null
    });
    const [switchChat, setSwitchChat] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState({});
    const chatBoxRef = useRef(null);
    const [imageModal, setImageModal] = useState(false);
    const [replyType, setReplyType] = useState('text');
    const [mediaContentOpen, setMediaContentOpen] = useState(false);
    const [accept, setAccept] = useState('image/*');
    const [pdfPreview, setPdfPreview] = useState(null);
    const [isSenderListVisible, setSenderListVisible] = useState(true);
    const [isChatBoxVisible, setChatBoxVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    // eslint-disable-next-line camelcase
    let userHolder = '';

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(`${baseUrl}/users?country=${country}`, config);
            const data = await response.json();

            // Add random image URL to each user
            const usersWithImages = data.users.map((user, index) => ({
                ...user,
                imageUrl: `https://api.dicebear.com/9.x/pixel-art/svg?seed=${index}&r=50&size=100`
            }));

            setUsers(usersWithImages);
            setLoading(false);
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const setupSocket = () => {
        const newSocket = io(socketUrl, {
            transports: ['websocket'],
            path: '/socket.io'
        });

        newSocket.on('connect', () => {});

        newSocket.on('mira_chat', (data) => {
            // Transform message content
            const transformedMessage = {
                phone: data.phone,
                content: data?.content?.body,
                image: data?.content?.link,
                type: data?.content?.type,
                isSent: data?.agent === 'mira' || data?.agent === 'staff',
                agent: data?.agent,
                user: selectedUser?.profile,
                createdAt: data?.createdAt
            };

            setMessages((prevMessages) => [...prevMessages, transformedMessage]);
            // Update user's last message timestamp
            setUsers((prevUsers) => {
                const updatedUsers = prevUsers.map((user) => {
                    if (user.phone === data.phone) {
                        return {
                            ...user,
                            lastMessageTime: data?.createdAt // Update the last message time
                        };
                    }
                    return user;
                });
                // Sort users by last message time, most recent first
                return updatedUsers.sort((a, b) => {
                    const dateA = new Date(a.lastMessageTime || 0);
                    const dateB = new Date(b.lastMessageTime || 0);
                    return dateB - dateA;
                });
            });
        });

        newSocket.on('disconnect', () => {
            // console.log('Socket.IO disconnected');
        });

        newSocket.on('connect_error', (error) => {
            // console.log('Socket.IO connect_error:', error);
        });

        setSocket(newSocket);
    };

    const sendMessage = () => {
        if ((socket && message.trim()) || !message) {
            const newMessage = {
                phone: selectedUser.phone,
                content: {
                    body: message,
                    type: messageType, // 'text' or 'image',
                    url: messageType === 'image' || messageType === 'document' ? imageUrl : ''
                },
                user_id: localStorage.getItem('userId'),
                replyTo: replyTo.whatsapp_id
            };
            socket.emit('mira_chat', newMessage);

            // Transform message content
            const transformedMessage = {
                phone: selectedUser.phone,
                content: message,
                type: messageType,
                isSent: 'mira' || 'staff',
                agent: 'staff',
                image: messageType === 'image' ? imageUrl : null,
                user: `${localStorage.getItem('userFirstName')} ${localStorage.getItem('userLastName')}`,
                createdAt: new Date().toISOString()
            };

            setMessages((prevMessages) => [...prevMessages, transformedMessage]);

            // eslint-disable-next-line no-use-before-define
            closePopover();

            setMessage('');
            setImageUrl('');
            setFileList({});
            setImageModal(false);
            setReplyTo({ _id: null, profile: null, content: null });
        }
    };

    const takeOverChat = async (phone) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const payload = {
                phone
            };
            const response = await fetch(`${baseUrl}/staff-take-over`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            toast(data.message);
            setSwitchChat(true);
        } catch (error) {
            //  console.log(error);
        }
    };
    const miraOverChat = async (phone) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const payload = {
                phone
            };
            const response = await fetch(`${baseUrl}/mira-take-over`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify(payload)
            });
            const data = await response.json();

            toast(data.message);
            setSwitchChat(false);
        } catch (error) {
            //  console.log(error);
        }
    };

    const scrollToBottom = () => {
        if (chatBoxRef.current) {
            if (window.innerWidth > 768) {
                // For larger screens, scroll to the bottom
                chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
            } else {
                // On mobile, directly show the last message without smooth scrolling
                const lastMessageElement = chatBoxRef.current.lastElementChild;
                if (lastMessageElement) {
                    lastMessageElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    };

    const fetchUserMessages = async (phone, index) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(`${baseUrl}/user?phone=${phone}`, config);
            const data = await response.json();

            // Add random image URL to each user
            const userWithImage = {
                ...data,
                imageUrl: `https://api.dicebear.com/9.x/pixel-art/svg?seed=${index}&r=50&size=100`
            };

            setSelectedUser(userWithImage);
            userHolder = data.profile;

            // Fetch messages

            // Transform message content
            const transformedMessages = data.messages.map((chat) => {
                let messageContent = chat.content.body;
                if (typeof messageContent === 'object') {
                    messageContent = messageContent.text.body;
                }
                let user;
                if (chat.agent === 'staff') {
                    user = `${chat.user?.firstName || ''} ${chat.user?.lastName || ''}`;
                } else if (chat.agent === 'mira') {
                    user = 'mira';
                } else {
                    user = data.profile;
                }

                return {
                    _id: chat._id,
                    phone: chat.phone,
                    content: messageContent,
                    image: chat?.content?.url,
                    type: chat.content.type,
                    isSent: chat.agent === 'mira' || chat.agent === 'staff',
                    agent: chat.agent,
                    createdAt: chat.createdAt,
                    profile: chat.agent === 'user' ? data.profile : 'mira',
                    whatsapp_id: chat.whatsapp_id,
                    user
                };
            });

            // Sort messages by date
            const sortedMessages = transformedMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

            setMessages(sortedMessages);

            // await takeOverChat(phone);

            scrollToBottom();
        } catch (error) {
            // console.log(error);
        }
    };

    useEffect(() => {
        if (phoneNumber.phone) {
            fetchUserMessages(phoneNumber.phone, phoneNumber.index);
        }
    }, [phoneNumber.phone, switchChat]);

    useEffect(() => {
        fetchUsers().then((r) => r);
    }, []);

    useEffect(() => {
        setupSocket();

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, []); // Empty dependency array means this effect runs once
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const onChange = (checked) => {
        setSwitchChat(checked);
        if (checked) {
            miraOverChat(phoneNumber?.phone).then((r) => r);
        } else {
            takeOverChat(phoneNumber?.phone).then((r) => r);
        }
    };

    const handleChatPopClick = (id) => {
        if (open === id) {
            setOpen(null);
            document.getElementById('chat-box-message').classList.remove('no-scroll');
        } else {
            setOpen(id);
            document.getElementById('chat-box-message').classList.add('no-scroll');
        }
    };

    const closePopover = () => {
        setOpen(false);
        document.getElementById('chat-box-message').classList.remove('no-scroll');
    };

    const replyToMessage = (message) => {
        setReplyTo({
            _id: message._id,
            profile: message.profile,
            content: message.content,
            whatsapp_id: message.whatsapp_id
        }); // Set the message to reply to
        closePopover(); // Close the popover
    };

    const actionContent = (message) => (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                alignItems: 'left',
                textAlign: 'left',
                backgroundColor: '#f9f9f9',
                padding: '10px',
                borderRadius: '10px',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                width: '200px'
            }}
        >
            <Button
                style={{
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                type="button"
                onClick={() => {
                    setReplyType('text');
                    replyToMessage(message);
                }}
            >
                Reply <CommentOutlined />
            </Button>
            <Button
                style={{
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                type="button"
                onClick={() => {
                    setReplyType('image');
                    setImageUrl(message.image);
                    setImageModal(true);
                    closePopover();
                    replyToMessage(message);
                }}
            >
                Image <FileImageOutlined />
            </Button>
        </div>
    );

    // eslint-disable-next-line consistent-return
    const imageUploadRequest = async (file) => {
        try {
            const token = localStorage.getItem('userToken');
            const rootURL = ApiUrl.BaseUrl;
            const compressedImage = await normFile(file, token, rootURL);
            if (compressedImage) {
                setFileList({ uid: file.uid, url: compressedImage, name: file.name });
                setImageUrl(compressedImage);
                setLoading(false);
                return {
                    status: 'done',
                    url: compressedImage
                };
            }
        } catch (error) {
            setLoading(false);
            message.error(error);
            toast.error(error.message);
        }
    };

    const handleDelete = (file) => {
        setFileList({});
    };

    const props = {
        name: 'file',
        multiple: false,
        accept,
        customRequest: ({ file, onSuccess, onError }) => {
            imageUploadRequest(file).then(onSuccess).catch(onError);
        },
        onChange(info) {
            const { status, uid } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                // console.log(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                // console.log(`${info.file.name} file upload failed.`);
            } else if (status === 'removed') {
                handleDelete(info.file);
            }
        },
        onRemove: (file) => {
            handleDelete(file);
        }
    };

    const mediaContent = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                alignItems: 'left',
                textAlign: 'left',
                backgroundColor: '#f9f9f9',
                padding: '10px',
                borderRadius: '10px',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                width: '150px'
            }}
        >
            <Button
                style={{
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                type="button"
                onClick={() => {
                    setAccept('application/pdf');
                    setMessageType('document');
                    setImageModal(true);
                    closePopover();
                }}
            >
                Document <FilePdfOutlined />
            </Button>
            <Button
                style={{
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                type="button"
                onClick={() => {
                    setAccept('image/*');
                    setMessageType('image');
                    setImageModal(true);
                    closePopover();
                }}
            >
                Image <FileImageOutlined />
            </Button>
        </div>
    );
    // Handle resizing for mobile screens
    const handleResize = () => {
        if (window.innerWidth < 768) {
            if (isChatBoxVisible) {
                setSenderListVisible(false);
                document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'none');
                scrollToBottom();
            } else {
                setSenderListVisible(true);
                document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'flex');
            }
        } else {
            setSenderListVisible(true);
            setChatBoxVisible(true);
            document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'flex');
            scrollToBottom();
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check
        scrollToBottom();
        return () => window.removeEventListener('resize', handleResize);
    }, [isChatBoxVisible]);

    const showSenderList = () => {
        setSenderListVisible(true);
        setChatBoxVisible(false);
        document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'none');
    };

    const hideSenderList = () => {
        setSenderListVisible(false);
        setChatBoxVisible(true);
        document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'flex');
        scrollToBottom();
    };

    // Filter user based on the search term
    const filteredUsers = users.filter((user) => user.profile.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <div className="chat-inbox-container">
            {isSenderListVisible && (
                <div className="senderList">
                    <span className="chat-title">
                        <h3>Staff Support</h3>
                    </span>
                    <div className="search-containter">
                        <input type="text" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                    {loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}
                        >
                            <Spin
                                indicator={
                                    <LoadingOutlined
                                        style={{
                                            fontSize: 48,
                                            color: '#FF740F'
                                        }}
                                        spin
                                    />
                                }
                            />
                        </div>
                    ) : (
                        <div>
                            {filteredUsers.map((user, index) => {
                                const lastMessage = messages.filter((msg) => msg.phone === user.phone).slice(-1)[0]?.content || user.phone;
                                const hasNewMessage = messages.some(
                                    (msg) => msg.phone === user.phone && !msg.isSent && msg.agent !== 'user'
                                );

                                return (
                                    <div
                                        className="sender"
                                        key={user._id}
                                        onClick={() => {
                                            if (window.innerWidth < 768) {
                                                hideSenderList();
                                            }
                                            setSelectedUser(user.profile);
                                            setPhoneNumber({ phone: user.phone, index });
                                            fetchUserMessages(user.phone, index);
                                        }}
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={() => fetchUserMessages(user.phone, index)}
                                    >
                                        <div className="senderImg">
                                            <img src={user?.imageUrl || 'https://randomuser.me/api/portraits/men/75.jpg'} alt="sender" />
                                        </div>
                                        <div className="sender-content">
                                            <div className="senderName">{user?.profile}</div>
                                            <div className="senderMessage">
                                                {hasNewMessage ? (
                                                    <strong className="new_message_indicator">New Message</strong>
                                                ) : (
                                                    lastMessage
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}

            {selectedUser && isChatBoxVisible && (
                <div className="chatBox">
                    <div className="chat-box-header">
                        <div className="chat-box-header-content">
                            <span className="back">
                                <LeftOutlined onClick={showSenderList} />
                            </span>
                            <div className="chatImg">
                                <img
                                    src={selectedUser?.imageUrl || 'Loading...'}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = 'https://via.placeholder.com/100';
                                    }}
                                    alt="sender"
                                />
                            </div>
                            <p>
                                <span className="user-name">
                                    <strong>{selectedUser?.profile || 'Loading...'}</strong>
                                </span>
                            </p>
                        </div>
                        <div className="chat-box-header-delete">
                            <Switch defaultChecked={switchChat} checkedChildren="Mira" onChange={onChange} />
                        </div>
                    </div>
                    <div className="chat-box-body">
                        <div id="chat-box-message" className="chat-box-message" ref={chatBoxRef}>
                            {messages
                                .filter((message) => message.phone === phoneNumber.phone)
                                .reduce((acc, message, index, array) => {
                                    const messageDate = dayjs(message.createdAt);
                                    const prevMessage = array[index - 1];
                                    const prevMessageDate = prevMessage ? dayjs(prevMessage.createdAt) : null;

                                    if (!prevMessageDate || !messageDate.isSame(prevMessageDate, 'day')) {
                                        acc.push(
                                            <div key={`date-${message._id}`} className="message-date-separator">
                                                <span>{messageDate.format('MMMM D, YYYY')}</span>
                                            </div>
                                        );
                                    }

                                    acc.push(
                                        <Popover
                                            content={actionContent(message)}
                                            key={message._id}
                                            className="popoverChat"
                                            trigger="click"
                                            open={open === message._id}
                                            onOpenChange={() => handleChatPopClick(message._id)}
                                            placement={open === message._id ? 'top' : 'right'}
                                        >
                                            <div className={message.isSent ? 'chat-box-message-send' : 'chat-box-message-recieve'}>
                                                <div className="message">
                                                    <div>
                                                        {message?.user && (
                                                            <span>
                                                                <small
                                                                    style={{
                                                                        color: '#A3BEB7',
                                                                        fontSize: '12px'
                                                                    }}
                                                                >
                                                                    {message.user}
                                                                </small>
                                                                <br />
                                                            </span>
                                                        )}
                                                        {message.content && (
                                                            <span className="mt-4">
                                                                <p> {message.content}</p>
                                                            </span>
                                                        )}
                                                        {message.type === 'image' && message.image && (
                                                            <span
                                                                className="mt-4 mb-4"
                                                                role="button"
                                                                tabIndex={0}
                                                                aria-label="View image"
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                                        // Trigger any action on key press if necessary
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src={message.image}
                                                                    onError={(e) => {
                                                                        e.target.onerror = null;
                                                                        e.target.src = 'https://via.placeholder.com/150';
                                                                    }}
                                                                    alt="img"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '200px',
                                                                        objectFit: 'cover',
                                                                        marginTop: '2px',
                                                                        marginBottom: '2px'
                                                                    }}
                                                                />
                                                                <br />
                                                            </span>
                                                        )}
                                                        {message.type === 'document' && message.image && (
                                                            <span
                                                                className="mt-4 mb-4"
                                                                role="button"
                                                                tabIndex={0}
                                                                aria-label="View document"
                                                                onClick={() => setPdfPreview(message.image)}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                                        setPdfPreview(message.image);
                                                                    }
                                                                }}
                                                            >
                                                                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                                                                <object data={message.image} type="application/pdf" className="pdf-preview">
                                                                    <embed
                                                                        src={message.image}
                                                                        type="application/pdf"
                                                                        width="100%"
                                                                        height="200"
                                                                        style={{ marginTop: '2px', marginBottom: '2px' }}
                                                                    />
                                                                </object>
                                                                <div className="pdf-download-content">
                                                                    <span>
                                                                        <img
                                                                            className="pdf-info"
                                                                            src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                                                                            alt="pdf"
                                                                            style={{ width: '50px', height: '50px' }}
                                                                        />
                                                                        {message.content}
                                                                    </span>
                                                                    <a href={message.image} download className="pdf-download-btn">
                                                                        <CloudDownloadOutlined />
                                                                    </a>
                                                                </div>
                                                            </span>
                                                        )}
                                                        {message.type === 'audio' && (
                                                            <span>
                                                                <audio controls className="audio-preview">
                                                                    {message.image && (
                                                                        <>
                                                                            <source src={message.image} type="audio/mpeg" />
                                                                            <source src={message.image} type="audio/ogg" />
                                                                            <source src={message.image} type="audio/wav" />
                                                                        </>
                                                                    )}
                                                                    <track kind="captions" src="" label="English captions" />
                                                                    Your browser does not support the audio element.
                                                                </audio>
                                                            </span>
                                                        )}
                                                        <small
                                                            style={{
                                                                color: '#A3BEB7',
                                                                display: 'flex',
                                                                justifyContent: 'flex-end',
                                                                marginTop: '10px'
                                                            }}
                                                        >
                                                            <small> {dayjs(message.createdAt).format('hh:mm A')}</small>
                                                        </small>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </Popover>
                                    );

                                    return acc;
                                }, [])}
                        </div>

                        {replyType === 'text' ? (
                            <span>
                                {replyTo.content && (
                                    <div className="replyToMessage">
                                        <p style={{ fontSize: '12px' }}>
                                            <strong className="profile">{replyTo.profile}</strong>
                                            <br /> {replyTo.content}
                                        </p>
                                        <button
                                            className="close-btn"
                                            type="button"
                                            onClick={() => {
                                                setReplyTo({ _id: null, profile: null, content: null });
                                            }}
                                        >
                                            x
                                        </button>
                                    </div>
                                )}
                            </span>
                        ) : null}

                        <div className="chat-box-footer">
                            <Popover
                                placement="topRight"
                                trigger="click"
                                content={mediaContent}
                                open={mediaContentOpen}
                                onOpenChange={(visible) => setMediaContentOpen(visible)}
                            >
                                <button
                                    style={
                                        mediaContentOpen
                                            ? {
                                                  backgroundColor: '#F6F6F6',
                                                  color: '#7B808C'
                                              }
                                            : { backgroundColor: '#fff' }
                                    }
                                    type="button"
                                >
                                    {mediaContentOpen ? <CloseOutlined /> : <PlusOutlined />}
                                </button>
                            </Popover>
                            <input
                                type="text"
                                placeholder="Type a message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                            />
                            <button type="button" onClick={sendMessage}>
                                <SendOutlined />
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Modal open={imageModal} onCancel={() => setImageModal(false)} footer={null} width={600}>
                <div style={{ padding: '20px' }}>
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg';
                            }}
                            alt="img"
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '10px',
                                objectFit: 'cover'
                            }}
                        />
                    ) : (
                        <span>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned
                                    files.
                                </p>
                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    {fileList.url && (
                                        <img
                                            src={fileList.url}
                                            onError={(e) => {
                                                e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg';
                                            }}
                                            alt={fileList.name}
                                            className="mr-2"
                                            style={{
                                                width: '100px',
                                                height: '50px',
                                                objectFit: 'contain',
                                                borderRadius: '10px',
                                                border: '1px solid #f0f0f0',
                                                padding: '5px'
                                            }}
                                        />
                                    )}
                                </div>
                            </Dragger>
                        </span>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: 'calc(89% - 20px)',
                            padding: '10px',
                            position: 'absolute',
                            bottom: '0',
                            marginBottom: '10px',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            border: '1px solid #f0f0f0'
                        }}
                    >
                        <input
                            style={{
                                width: 'calc(100% - 50px)',
                                padding: '5px',
                                borderRadius: '5px',
                                border: 'none',
                                outline: 'none',
                                height: '30px'
                            }}
                            type="text"
                            placeholder="Type a message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                        />
                        <button
                            type="button"
                            onClick={() => {
                                sendMessage();
                            }}
                            style={{
                                backgroundColor: '#F6F6F6',
                                color: '#7B808C',
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '100px',
                                marginLeft: '10px',
                                cursor: 'pointer',
                                fontSize: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <SendOutlined />
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Index;
