// import { lazy } from 'react';

import AddAttribute from 'views/setups/AddAttribute';
import AddAutoAccount from 'views/autolab/AddAutoAccount';
import AddBatchAutomation from 'views/setups/addbatchautomation';
import AddBatchStatus from 'views/setups/AddBatchStatus';
import AddCar from 'views/car/AddCar';
import AddCarModel from 'views/carmodel/AddCarModel';
import AddCarrierPage from 'views/setups/AddCarrier';
import AddChannelPage from 'views/channel/AddChannel';
import AddCity from 'views/city/AddCity';
import AddClaimId from 'views/claimId/AddClaimId';
import AddClaimTypes from 'views/claimTypes/AddClaimTypes';
import AddCollection from 'views/sales/addcollections';
import AddCollectionEmail from 'views/collectionemail/AddCollectionEmail';
import AddComparePrice from 'views/compareprice/AddComparePrice';
import AddContactOrder from 'views/contactorder/AddContactOrder';
import AddCountry from 'views/country/AddCountry';
import AddCustomer from 'views/autolab/addCustomer';
import AddCustomerComplaint from 'views/autolab/AddCustomerComplaint';
import AddCustomerSuccessTeam from 'views/customersuccessteam/AddCustomerSuccessTeam';
import AddDarkStoreArea from 'views/setups/AddDarkStoreArea';
import AddDeliveryImage from 'views/orders/addDeliveryImage';
import AddDeliveryImage2 from 'views/orders/addDeliveryImage2';
import AddDispatchImage from 'views/orders/addDispatchImage';
import AddDispatchImage2 from 'views/orders/addDispatchImage2';
import AddDistributorPage from 'views/distributor/AddDistributor';
import AddDriverImage from 'views/orders/AddDriverImage';
import AddFleetAccount from 'views/fleet/fleetaccount/addfleetaccount';
import AddFleetCar from 'views/fleet/vehicleUpload/AddFleetCar';
import AddFleetCarModel from 'views/fleet/vehicleUpload/AddFleetCarModel';
import AddFleetDriverOrderParts from 'views/fleet/fleetdriver/addfleetdriverorder';
import AddFleetEmailOrder from 'views/fleet/fleetemail/addfleetemail';
import AddFleetIndividualPartner from 'views/fleetpartner/AddFleetIndividualPartner';
import AddFleetManagerReminder from 'views/fleet/fleetreminder/AddFleetManagerReminder';
import AddFleetOrderParts from 'views/fleet/fleetorderparts/addfleetorderparts';
import AddFleetPart from 'views/fleet/partsetup/AddPartFleet';
import AddFleetPartner from 'views/fleetpartner/AddFleetPartner';
import AddFleetRequestService from 'views/fleet/fleetrequestservice/addfleetrequestservice';
import AddFleetService from 'views/fleet/fleetservice/addfleetservice';
import AddFleetServiceFee from 'views/fleet/fleetservicefee/addfleetservicefee';
import AddFleetServiceId from 'views/fleet/fleetserviceid/addfleetserviceid';
import AddFleetVehicleRequest from 'views/fleet/fleetvehiclerequest/AddFleetVehicleRequest';
import AddGarageReminder from 'views/fleet/garagereminder/AddGarageReminder';
import AddIDistributornventory from 'views/inventory/AddDistributorInventory';
import AddIndividualFleetVehicleRequest from 'views/fleetpartner/AddIndividualVehicleRequest';
import AddInspectionReport from 'views/inspectionreport/AddInspectionReport';
import AddInventory from 'views/inventory/AddInventory';
import AddIssueDiagnosed from 'views/autolab/AddIssueDiagnosed';
import AddIssuesOrder from 'views/sales/addIssue';
import AddJobCard from 'views/autolab/AddJobCard';
import AddJobCardFleet from 'views/autolab/JobCardFleetAdd';
import AddLeadEmail from 'views/leademail/AddLeadEmail';
import AddLotDetails from 'views/lotdetails/AddLotDetails';
import AddLoyalty from 'views/setups/AddLoyaltyList';
import AddMechanic from 'views/mechanic/AddMechanic';
import AddMyFleetOrderParts from 'views/fleet/fleetorderparts/addmyfleetordersparts';
import AddNewOrder from 'views/neworder/AddNewOrder';
import AddOrder from 'views/orders/AddOrder';
import AddOrderParts from 'views/insurerOrderParts/AddOrderParts';
import AddOrderStatusPage from 'views/setups/AddOrderStatus';
import AddOrderTag from 'views/orders/AddOrderTag';
import AddOrderType from 'views/setups/AddOrderType';
import AddPackSortImage from 'views/orders/addPackSortImage';
import AddPackSortImage2 from 'views/orders/addPackSortImage2';
import AddPartListNames from 'views/parts/AddPartListNames';
import AddPartZones from 'views/parts/AddPartZones';
import AddPartsGarage from 'views/partsgarage/AddPartsGarage';
import AddPartsOrderedFleet from 'views/fleet/orderpartfleet/addpartorderedfleet';
import AddPartsPage from 'views/master_parts/add/Index';
import AddPaymentStatusPage from 'views/setups/AddPaymentStatus';
import AddPickDrop from 'views/setups/AddPickDrop';
import AddPolicyId from 'views/policyid/AddPolicyId';
import AddPriorityAutomation from 'views/setups/addpriorityAutomation';
import AddRequestDiagnostics from 'views/requestdiagnostics/AddRequestDiagnostics';
import AddRequestService from 'views/requestservice/AddRequestService';
import AddRequestServicingFleet from 'views/fleet/requestservicingfleet/AddRequestServicingFleet';
import AddRetailer from 'views/retailer/AddRetailer';
import AddRole from 'views/roles/AddRole';
import AddSalesPod from 'views/salesDrive/AddSalesPod';
import AddSalesTeam from 'views/salesteam/AddSalesTeam';
import AddService from 'views/Service/AddService';
import AddServiceFee from 'views/servicefee/AddServiceFee';
import AddServiceStatus from 'views/serviceStatus/AddServiceStatus';
import AddShopPartner from 'views/sales/AddShop';
import AddStage from 'views/setups/AddStage';
import AddStagingArea from 'views/setups/AddStagingArea';
import AddTagPage from 'views/setups/AddTag';
import AddTargets from 'views/salesplanner/AddTarget';
import AddTodoList from 'views/salesplanner/AddTodoList';
import AddUsersPage from 'views/users/AddUser';
import AddWareHouse from 'views/warehouse/AddWareHouse';
import AllFleetManagerReminder from 'views/fleet/garagereminder/AllFleetReminder';
import AllGarageManagerReminder from 'views/fleet/garagereminder/AllGarageManagerReminder';
import AllGroupPartsSold from 'views/totalsales/AllGroupPartsSold';
import AllMessages from 'views/Messages/AllMessages';
import AllNotes from 'components/DeliveryNotes/AllNotes';
import AllOrdersParts from 'views/totalsales/AllOrdersParts';
import AllPartsSold from 'views/totalsales/AllPartsSold';
import AllRequestServicingFleet from 'views/fleet/requestservicingfleet/AllRequestServicingFleet';
import AllRetailersOrders from 'views/totalsales/AllRetailersOrders';
import AllSalesPersonSales from 'views/totalsales/AllSalesPersonOrders';
import AllTotalSales from 'views/totalsales/AllFulfilledSales';
import AllUpdateRequestServicingFleet from 'views/fleet/requestservicingfleet/AllUpdateRequestServicing';
import AllViewingRequestServicingFleet from 'views/fleet/requestservicingfleet/AllViewRequestServicing';
import ApproveCssVehicleRequest from 'views/fleet/fleetvehiclerequest/ApproveCssVehicleRequest';
import ApproveFleetVehicleRequest from 'views/fleet/fleetvehiclerequest/ApproveFleetVehicleRequest';
import ApproveLink from 'views/orders/approvelink';
import Attribute from 'views/setups/Attribute';
import AuditLogsOrder from 'views/orders/AuditLogsOrder';
import AutoAccount from 'views/autolab/AutoAccount';
import BatchAutomation from 'views/setups/batchautomation';
import BatchOrders from 'components/supplier/BatchOrders';
import BatchStatus from 'views/setups/BatchStatus';
import BatchesPage from 'views/totalsales/BatchesPage';
import BulkUploadFleetPartner from 'views/fleetpartner/bulkuploadfleetpartners';
import BulkVehicleUpload from 'views/fleet/vehicleUpload/bulkuploadvehicle';
import CCLead from 'views/sales/cclead';
import CSPartsPriceList from 'views/parts/CSPartsPriceList';
import CSSResolvedOrderIssue from 'views/orders/cssResolvedOrderIssue';
import CalendarRequestServicing from 'views/fleet/requestservicingfleet/CalendarRequestServicing';
import Car from 'views/car/Car';
import CarModel from 'views/carmodel/CarModel';
import CarrierPage from 'views/setups/CarrierList';
import ChannelPage from 'views/channel/Channel';
import City from 'views/city/City';
import ClaimId from 'views/claimId/ClaimId';
import ClaimTypes from 'views/claimTypes/ClaimTypes';
import CollectionEmail from 'views/collectionemail/CollectionEmail';
import CollectionOrders from 'views/orders/Collection';
import CollectionReport from 'views/reporting/collectionreport';
import ComparePrices from 'views/compareprice/ComparePrice';
import ContactOrder from 'views/contactorder/ContactOrder';
import CorrectOrder from 'views/orders/CorrectOrder';
import Country from 'views/country/Country';
import CssOrderIssue from 'views/orders/cssOrderIssue';
import CssOrderRequestForm from 'views/orders/cssOrderRequestForm';
import CssOrders from 'views/orders/CssOrders';
import Customer from 'views/autolab/customer';
import CustomerComplaint from 'views/autolab/CustomerComplaint';
import CustomerSatisfaction from 'views/customersatisfaction/CustomerSatisfaction';
import CustomerSuccessDashboard from 'views/customersuccessdashboard/CustomerSuccessDashboard';
import CustomerSuccessTeam from 'views/customersuccessteam/CustomerSuccessTeam';
import DarkStore from 'views/darkstores/DarkStore';
import DarkStoreArea from 'views/setups/DarkStoreArea';
// import DarkStoreOrders from 'views/orders/DarkStoreOrders';
// dashboard routing
import DashboardDefault from 'views/dashboard/Default';
import DashboardRetailerIndex from 'views/dashboardRetailer/dashboardRetailerIndex';
import DataPortal from 'views/DataPortal';
import DeliveryNote from 'components/DeliveryNotes/DeliveryNote';
import DeliveryNoteImagePreview from 'views/ordernotes/deliverypreview';
import DeliveryNoteImageReport from 'views/ordernotes/deliverynote';
import DepositReport from 'views/reporting/depositreport';
import DepositedOrders from 'views/orders/Deposited';
import DispatchImagePreview from 'views/ordernotes/dispatchpreview';
import DispatchImageReport from 'views/ordernotes/dispatch';
import DistributorDashboard from 'views/distributordashboard/DistributorDashboard';
import DistributorInventory from 'views/inventory/DistributorInventory';
import DistributorPage from 'views/distributor/Distributors';
import DriverAllOrders from 'views/ordernotes/drivernotes';
import DriverOrders from 'views/orders/retrieveDriverOrder';
import EditAttribute from 'views/setups/EditAttribute';
import EditCar from 'views/car/EditCar';
import EditCarModel from 'views/carmodel/EditCarModel';
import EditCarrierPage from 'views/setups/EditCarrier';
import EditChannelPage from 'views/channel/EditChannel';
import EditCity from 'views/city/EditCity';
import EditCollectionEmail from 'views/collectionemail/EditCollectionEmail';
import EditContactOrder from 'views/contactorder/EditContactOrder';
import EditCustomer from 'views/autolab/editCustomer';
import EditDistributorPage from 'views/distributor/EditDistributor';
import EditFleetCar from 'views/fleet/vehicleUpload/EditFleetCar';
import EditFleetCarModel from 'views/fleet/vehicleUpload/EditFleetCarModel';
import EditFleetManagerReminder from 'views/fleet/fleetreminder/EditFleetManagerReminder';
import EditInActiveParts from 'views/parts/EditInActivePart';
import EditLeadEmail from 'views/leademail/EditLeadEmail';
import EditMechanic from 'views/mechanic/EditMechanic';
import EditOrderStatusPage from 'views/setups/EditOrderStatus';
import EditOrderType from 'views/setups/EditOrderType';
import EditPartsPage from 'views/master_parts/edit/Index';
import ViewPartsDiscount from 'views/master_parts/discount/Index';
import AddPartsDiscount from 'views/master_parts/discount/add/Index';
import EditPartsDiscount from 'views/master_parts/discount/edit/Index';
import EditPaymentStatusPage from 'views/setups/EditPaymentStatus';
import EditPickDrop from 'views/setups/EditPickDrop';
import EditPolicyId from 'views/policyid/EditPolicyId';
import EditPriorityAutomation from 'views/setups/editPriorityAutomation';
import EditRequest from 'views/requestservice/EditRequest';
import EditRetailer from 'views/retailer/EditRetailer';
import EditRole from 'views/roles/EditRole';
import EditSalesPod from 'views/salesDrive/EditSalesPod';
import EditServiceFee from 'views/servicefee/EditServiceFee';
import EditStage from 'views/setups/EditStage';
import EditTagPage from 'views/setups/EditTag';
import EditUserPage from 'views/users/EditUser';
import Emissions from 'views/emissions/Emissions';
import EnterPriseMotorAccident from 'views/InsurerForms/enterpriseMotorAccident';
// import ExternalSignup from 'views/pages/authentication/ExternalSignup';
import FleetAccount from 'views/fleet/fleetaccount/fleetaccount';
import FleetCar from 'views/fleet/vehicleUpload/FleetCar';
import FleetCarModel from 'views/fleet/vehicleUpload/FleetCarModel';
import FleetDriverAddRequestService from 'views/fleet/fleetdriver/addfleetdriverrequestservice';
import FleetDriverOrderParts from 'views/fleet/fleetdriver/fleetdriverorderparts';
import FleetDriverRequestService from 'views/fleet/fleetdriver/fleetdriverrequestservice';
import FleetDriverViewRequestService from 'views/fleet/fleetdriver/viewfleetdriverrequestservice';
// import ShowFleetDriverOrderParts from 'views/fleet/fleetdriver/showfleetdriverorderparts';
import FleetEmailOrder from 'views/fleet/fleetemail/fleetemail';
import FleetManagerReminder from 'views/fleet/fleetreminder/FleetManagerReminder';
import FleetOrderParts from 'views/fleet/fleetorderparts/fleetorderparts';
import FleetPart from 'views/fleet/partsetup/PartFleet';
import FleetPartner from 'views/fleetpartner/FleetPartner';
import FleetPartnerIndividual from 'views/fleetpartner/FleetPartnerIndividual';
import FleetRequestService from 'views/fleet/fleetrequestservice/fleetrequestservice';
import FleetService from 'views/fleet/fleetservice/fleetservice';
import FleetServiceFee from 'views/fleet/fleetservicefee/fleetservicefee';
import FleetServiceId from 'views/fleet/fleetserviceid/fleetserviceid';
import FleetSettings from 'views/fleet/fleetsettings';
import FleetVehicleProfile from 'views/fleet/garagereminder/fleetvehicleprofile';
import FleetVehicleRequest from 'views/fleet/fleetvehiclerequest/FleetVehicleRequest';
import FulfilledPreview from 'views/orders/FulfilledPreview';
import GarageFleetRequestService from 'views/fleet/fleetrequestservice/garagefleetrequestservice';
import GarageManagerCalender from 'views/fleet/requestservicingfleet/CalendarRequestGarageManager';
import InActivePartList from 'views/parts/UnApprovedPart';
import InspectionReport from 'views/inspectionreport/InspectionReport';
import Inventory from 'views/inventory/Inventory';
import InventoryOrdersDistributor from 'views/inventory/InventoryOrders';
import IssueDiagnosed from 'views/autolab/IssueDiagnosed';
import IssuesOrder from 'views/orders/Issues';
import JobCardFleet from 'views/autolab/JobCardFleet';
import JobOrder from 'views/autolab/JobOrders';
import Jobcard from 'views/autolab/Jobcard';
import Lead from 'views/sales/lead';
import LeadEmail from 'views/leademail/LeadEmail';
import LotDetails from 'views/lotdetails/LotDetails';
import Loyalty from 'views/LoyaltyPrg/Loyalty';
import LoyaltyList from 'views/setups/LoyaltyList';
// project imports
import MainLayout from 'layout/MainLayout';
import Mechanic from 'views/mechanic/Mechanic';
import MechanicPartners from 'views/mechanicPartners/MechanicPartners';
import MechanicSettings from 'views/sample-page/mechanicsettings';
import MyFleetOrderParts from 'views/fleet/fleetorderparts/myfleetorderparts';
import MySalesOrder from 'views/sales/myorder';
import NewLead from 'views/newlead/NewLead';
import NewOrder from 'views/neworder/NewOrder';
import NewRetailer from 'views/retailer/newRetailer';
import NewRetailers from 'views/newretailers/NewRetailers';
// import NotFound from 'views/Error/404';
import NoteConfirmation from 'components/DeliveryNotes/NoteConfirmation';
import Order from 'views/orders/Order';
// import Loadable from 'ui-component/Loadable';
import OrderBatchView from 'components/OrderBatching/OrderBatchView';
import OrderList from 'views/orderlist/OrderList';
import OrderParts from 'views/insurerOrderParts/OrderParts';
import OrderPartsFleetList from 'views/fleet/orderpartfleet/orderpartsfleetlist';
import OrderPriority from 'components/OrderPriority';
import OrderReport from 'views/reporting/orderreport';
import OrderRequest from 'views/pages/authentication/OrderRequest';
import OrderStatusPage from 'views/setups/OrderStatusList';
import OrderTableStatus from 'views/orders/OrderTableStatus';
import MessageGroups from 'views/Messages/Groups';
import OrderType from 'views/setups/OrderType';
import PackSortImagePreveiw from 'views/ordernotes/packsortpreview';
import PackSortImageReport from 'views/ordernotes/packsort';
import PartListNames from 'views/parts/PartListNames';
import PartOrdersFleetCss from 'views/fleet/orderpartfleet/partorderscss';
import PartPriceListSales from 'views/sales/partsprices';
import PartSourced from 'views/parts/PartSourced';
import PartZones from 'views/parts/PartZones';
import PartsGarage from 'views/partsgarage/PartsGarage';
import PartsOrderedFleet from 'views/fleet/orderpartfleet/partorderedfleet';
import PartsPage from 'views/master_parts/Index';
import PartsUploadEdit from 'views/parts/PartsUploadEdit';
import PartsUploadTest from 'views/parts/PartsUpload';
import PaymentStatusPage from 'views/setups/PaymentStatusList';
import PickDrop from 'views/setups/PickDrop';
import PlaceBatchOrder from 'components/supplier/PlaceBatchOrder';
import PolicyId from 'views/policyid/PolicyId';
import PreviewDriverImage from 'views/ordernotes/previewdrivernote';
import PreviewJobCardFleet from 'views/autolab/JobCardFleetPreview';
import PreviewMySalesOrder from 'views/sales/previeworders';
import PreviewSalesPartner from 'views/sales/previewsalespartners';
import PriceChanges from 'views/parts/PriceChanges';
import PriorityAutomation from 'views/setups/priorityAutomation';
import ProformCssOrders from 'views/orders/ProformaOrders';
import PresoldOrders from 'views/orders/PreSoldOrder';
import RMAPresoldOrders from 'views/sales/rmapresoldorders';
import PodPresoldOrders from 'views/sales/podpresoldorders';
import ConversionOrders from 'views/orders/ConversionDriveOrder';
import ProformaOrderUpdateCss from 'views/orders/updateProformaOrder';
import RequestDiagnostics from 'views/requestdiagnostics/RequestDiagnostics';
import RequestService from 'views/requestservice/RequestService';
import RequestServicingFleet from 'views/fleet/requestservicingfleet/RequestServicingFleet';
import ResolvedIssue from 'views/orders/resolvedIssues';
import Retailer from 'views/retailer/Retailer';
import RetailerDebitHistory from 'views/retailerDebitTracker/RetailerAllOrdersTracker';
import RetailerDebitTracker from 'views/retailerDebitTracker/RetailerDebitTracker';
import RetailerOrderHistory from 'views/retailer/retailerOrderHistory';
import RetailerPartners from 'views/retailerPartners/RetailerPartners';
import RetailerShop from 'views/RetailerShop/RetailerShop';
// componenents imports
import RolesPage from 'views/roles/Roles';
import SalePersonDelivery from 'components/DeliveryNotes/SalePersonDelivery';
import Sales from 'views/sales/sales';
// import UnApprovedDepositReport from 'views/reporting/unapproveddeposit';
import SalesCollectionReport from 'views/reporting/salescollectionreport';
import SalesDepositedReport from 'views/reporting/salesdepositreport';
import SalesDriveCssOrders from 'views/orders/SalesDriveOrders';
import RetentionDriveOrders from 'views/orders/RetentionDriveOrders';
import SalesPartner from 'views/sales/salespartners';
import SalesPod from 'views/salesDrive/salesPod';
import SalesTeam from 'views/salesteam/SalesTeam';
import SamplePage from 'views/sample-page/index';
import Service from 'views/Service/Service';
import ServiceFee from 'views/servicefee/ServiceFee';
import ServiceStatus from 'views/serviceStatus/ServiceStatus';
import ShowDistributorInventory from 'views/inventory/ShowDistributorInventory';
import ShowFleetManagerReminder from 'views/fleet/fleetreminder/ShowFleetManagerReminder';
import ShowFleetOrderParts from 'views/fleet/fleetorderparts/showfleetorderparts';
import ShowFleetRequestService from 'views/fleet/fleetrequestservice/showfleetrequestservice';
import ShowFleetVehicleRequest from 'views/fleet/fleetvehiclerequest/ShowFleetVehicleRequest';
import ShowGarageFleetRequestService from 'views/fleet/fleetrequestservice/showgaragefleetrequestservice';
import ShowInventory from 'views/inventory/ShowInventory';
import ShowJobCard from 'views/autolab/ShowJobCard';
import ShowMyFleetOrderParts from 'views/fleet/fleetorderparts/showmyfleetordersparts';
import ShowOrder from 'views/orders/ShowOrder';
import SingleOrder from 'components/DeliveryNotes/SingleOrder';
import SoldPartsDistributor from 'views/inventory/soldpartsdistributor';
import Stage from 'views/setups/Stage';
import StagingArea from 'views/setups/StagingArea';
import StockAdjustment from 'views/inventory/StockAdjustment';
import StockHistory from 'components/DarkStoreInventory/StockHistory';
import StockInventoryDistributor from 'views/inventory/stockInventoryDistributor';
import StoreInventory from 'components/DarkStoreInventory/StoreInventory';
import SupplierBatch from 'views/supplierdash/SupplierBatch';
import TagPage from 'views/setups/Tag';
import Targets from 'views/salesplanner/Targets';
import TodoList from 'views/salesplanner/Todolist';
import Transactions from 'views/transactions/Transactions';
import UpdateInventory from 'views/inventory/UpdateInventory';
import UpdateJobOrder from 'views/autolab/UpdateJobOrder';
import UpdateNote from 'components/DeliveryNotes/UpdateNote';
import UpdatePartOrderCssFleet from 'views/fleet/orderpartfleet/partsordercssupdate';
import UpdatePartsOrderFleet from 'views/fleet/orderpartfleet/updateorderpartsfleet';
import UpdateRequestServicingFleet from 'views/fleet/requestservicingfleet/UpdateRequestServicingFleet';
import UsersPage from 'views/users/Users';
import VehicleProfile from 'views/fleet/garagereminder/vehicleprofile';
import ViewAllFleetReminder from 'views/fleet/garagereminder/ViewAllFleetReminder';
import ViewBatchAutomation from 'views/setups/viewbatchautomation';
import ViewBatchStatus from 'views/setups/ViewBatchStatus';
import ViewClaimId from 'views/claimId/ViewClaim';
import ViewClaimTypes from 'views/claimTypes/ViewClaimTypes';
import ViewCollectionOrders from 'views/orders/ViewCollections';
import ViewComparePrices from 'views/compareprice/ViewComparePrice';
import ViewCountry from 'views/country/ViewCountry';
import ViewCssOrder from 'views/sales/viewcssorder';
import ViewCssVehicleRequest from 'views/fleet/fleetvehiclerequest/ViewCssVehicleRequest';
import ViewCustomerComplaint from 'views/autolab/ViewCustomerComplaint';
import ViewCustomerSuccessTeam from 'views/customersuccessteam/ViewCustomerSuccessTeam';
import ViewDarkStoreArea from 'views/setups/ViewDarkStoreArea';
import ViewDepositedOrders from 'views/orders/ViewDeposited';
import ViewFleetDriverOrderParts from 'views/fleet/fleetdriver/viewfleetdriverorderpart';
import ViewFleetEmailOrder from 'views/fleet/fleetemail/viewfleetemail';
import ViewFleetIndividualPartner from 'views/fleetpartner/ViewFleetIndividualPartner';
import ViewFleetOrderParts from 'views/fleet/fleetorderparts/viewfleetorderparts';
import ViewFleetPart from 'views/fleet/partsetup/ViewPart';
import ViewFleetPartner from 'views/fleetpartner/ViewFleetPartner';
import ViewFleetRequestService from 'views/fleet/fleetrequestservice/viewfleetrequestservice';
import ViewFleetService from 'views/fleet/fleetservice/viewfleetservice';
import ViewFleetServiceFee from 'views/fleet/fleetservicefee/viewfleetservicefee';
import ViewFleetServiceId from 'views/fleet/fleetserviceid/viewfleetserviceid';
import ViewFleetVehicleRequest from 'views/fleet/fleetvehiclerequest/ViewFleetVehicleRequest';
import ViewInspectionReport from 'views/inspectionreport/ViewInspectionReport';
import ViewIssueDiagnosed from 'views/autolab/ViewIssueDiagnosed';
import ViewJobCard from 'views/autolab/ViewJobCard';
import ViewJobCardFleet from 'views/autolab/JobCardFleetView';
import ViewJobOrder from 'views/autolab/ViewJobOrder';
import ViewLoyalty from 'views/setups/EditLoyaltyList';
import ViewMyFleetOrderParts from 'views/fleet/fleetorderparts/viewmyfleetorderparts';
import ViewMySalesOrder from 'views/sales/viewmyorders';
import ViewPreSoldOrders from 'views/sales/viewpresoldorders';
import ViewNewOrder from 'views/neworder/ViewNewOrder';
import ViewOrder from 'views/orders/ViewOrder';
import ViewOrderParts from 'views/insurerOrderParts/ViewOrderParts';
import ViewPartListNames from 'views/parts/ViewPartListNames';
import ViewPartZones from 'views/parts/ViewPartZones';
import ViewPartsGarage from 'views/partsgarage/ViewPartsGarage';
import ViewRequestDiagnostics from 'views/requestdiagnostics/ViewRequestDiagnostics';
import ViewRequestServicingFleet from 'views/fleet/requestservicingfleet/ViewRequestServicingFleet';
import ViewRetailerDebitTracker from 'views/orders/ViewRetailerDebitTracker';
import ViewRetailerOrderHistory from 'views/retailer/viewRetailerOrder';
import CCUpdateOrder from 'views/orders/ccUpdateOrder';
import ViewSalesPartner from 'views/sales/viewsalespartners';
import ViewSalesPod from 'views/salesDrive/ViewSalesPod';
import ViewSalesTeam from 'views/salesteam/ViewSalesTeam';
import ViewService from 'views/Service/ViewService';
import ViewServiceStatus from 'views/serviceStatus/ViewServiceStatus';
import ViewStagingArea from 'views/setups/EditStagingArea';
import ViewTargets from 'views/salesplanner/ViewTarget';
import ViewTodoList from 'views/salesplanner/ViewTodoList';
import ViewTransactions from 'views/transactions/ViewTransaction';
import ViewWareHouse from 'views/warehouse/ViewWareHouse';
import VinSearch from 'views/vinsearch/VinSearch';
import WareHouse from 'views/warehouse/WareHouse';
import SalesDriveBulk from 'views/sales/SalesDriveBulk';
import AddSalesDriveBulk from 'views/sales/AddSalesDriveBulk';
import ShowSalesDriveBulk from 'views/sales/ShowSalesDriveBulk';
import UpdateSalesDriveBulk from 'views/sales/UpdateSalesDriveBulk';
import OrderPayment from 'views/orders/orderPayment';
import MoMoCollection from 'views/orders/MoMoCollection';
import AllMoMoCollection from 'views/orders/AllMoMoCollections';
import AddMoMoCollection from 'views/orders/AddMoMoCollection';
import PreviewMoMoCollection from 'views/orders/previewMoMoCollection';
import RetailerAuditLog from 'views/retailer/RetailerAuditLog';
import Map from 'views/map/Map';
import QuickLinks from 'views/quickLinks/QuickLinks';
import Permission from 'views/permission/Permission';
import { element } from 'prop-types';
import Gender from 'views/retailerPartners/Gender';
import ImageUpload from 'views/attendance/ImageUpload';
import CheckIn from 'views/attendance/CheckIn';
import ViewCheckIn from 'views/ViewCheckIn/ViewCheckIn';
import Logistics from 'views/logistics/Logistics';
import AssignOrder from 'views/logistics/AssignOrder';
import Tracking from 'views/logistics/Tracking';

import SalesPlan from 'views/salesPlan/Index';
import SalesPlanCreate from 'views/salesPlan/create/Index';
import EditSalesPlan from 'views/salesPlan/edit/Index';
import BulkEditSalesPlan from 'views/salesPlan/bulk_edit/Index';
import SalesDriveType from 'views/salesPlan/driveType/Index';
import SalesDriveLocation from 'views/salesPlan/driveLocation/Index';
import OrderDetails from 'views/logistics/OrderDetails';
import TrackingAllDrivers from 'views/logistics/TrackingAllDrivers';
import FleetAllocation from 'views/fleetAllocation/FleetAllocation';
import AssignFleet from 'views/fleetAllocation/AssignFleet';
import TrackingFleet from 'views/fleetAllocation/TrackingFleet';
import EditFleetForm from 'views/fleetAllocation/EditFleetForm';

import ViewKPI from 'views/kpi/Index';
import SingleKPI from 'views/kpi/view/Index';
import SetKPI from 'views/kpi/create/Index';
import EditKPI from 'views/kpi/edit/Index';
import Presoldsales from 'views/sales/presoldsales';
import AddVehicles from 'views/fleetAllocation/AddVehicles';
import AllGarageVehicles from 'views/fleetAllocation/AllGarageVehicles';

import WayBill from 'views/waybill/Index';
import CreateWayBill from 'views/waybill/create/Index';
import EditWayBill from 'views/waybill/edit/Index';
import ViewWayBill from 'views/waybill/details/Index';

import Batch from 'views/waybill/batch/Index';
import SingleBatch from 'views/waybill/batch/singleBatch/Index';
import EditBatch from 'views/waybill/batch/edit/Index';

import GeneratedWayBill from 'views/waybill/generatedWaybill/Index';
import SingleGeneratedWayBill from 'views/waybill/generatedWaybill/singleWaybill/Index';
import PrintWayBill from 'views/waybill/generatedWaybill/printWaybill/Index';
import Corhot from 'views/corhot/Index';

import GrowthView from 'views/growthview/Index';
import ClearanceSheet from 'views/clearance/Index';
import CollectionPaymentStatus from 'views/collectioPaymentStatus/Index';

import Presold from 'views/presold/Index';
import PresoldBatch from 'views/presold/batch/Index';
import PresoldSingleBatch from 'views/presold/single/Index';
import RetailerProfile from 'views/retailerProfile/retailerProfile';
import RetailerAnalytics from 'views/retailerProfile/retailerAnalytics';
import RetailersOrderHistory from 'views/retailerProfile/orderHistory';
import RetailerMap from 'views/retailerProfile/retailerMap';
import RetailerReport from 'views/retailerProfile/retailerReport';
import RetailerProfileMap from 'views/retailerProfile/retailerProfileMap';
import CssPlacedOrder from 'views/customersuccessteam/cssplacedorders';
import SingleRetailerProfile from 'views/retailerProfile/SingleRetailerProfile';

import CRM from 'views/crm/index';
import SingleCRM from '../views/crm/crmDetail/index';
import CrmAnalytics from 'views/crm/crmAnalytics/index';
import CrmNotes from 'views/crm/notes/Index';

import RetailerLoyalty from 'views/loyaltyManagement';
import RetailerLoyaltyDetail from 'views/loyaltyManagement/details';
import RetailerLoyaltySummary from 'views/loyaltyManagement/summary';
import RetailerLoyaltyRewards from 'views/loyaltyManagement/rewards';
import Notes from 'views/retailerProfile/Notes';
import RmaRetailerDashboard from 'views/retailerProfile/RmaRetailerDashboard';
import AssignTasks from 'views/retailerProfile/AssignTasks';
import Reminders from 'views/retailerProfile/Reminders';
import { quicklinks } from 'menu-items/quicklinks';
import AllPayments from 'views/orders/AllPayments';
import SleepOver from 'views/sleepOver/SleepOver';
import AdminOrderRequestForm from 'views/adminorderrequestform/AdminOrderRequestForm';
import AllRetailerNotes from 'views/retailerProfile/AllRetailerNotes';
import RmaCcTasks from 'views/retailerProfile/RmaCcTasks';

import RmaRouteDashboard from 'views/routePlanning/Rma/dasboard/Index';
import RmaRouteReport from 'views/routePlanning/Rma/report/Index';
import RmaRouteRetailers from 'views/routePlanning/Rma/retailers/Index';

import AdminRouteDashboard from 'views/routePlanning/admin/dasboard/Index';
import AdminRouteReport from 'views/routePlanning/admin/report/Index';
import MiraInbox from 'views/mira/Index';
import EPLConfiguration from 'views/epl/configuration/Index';
import EasyPay from 'views/epl/easypay/Index';
import AddEasyPayCredit from 'views/epl/credit/Add';
import EditEasyPayCredit from 'views/epl/credit/Edit';
import AddEasyPayPayment from 'views/epl/payment/Add';
import EditEasyPayPayment from 'views/epl/payment/Edit';
import EplSummary from 'views/epl/summary/Index';
import EplRequest from 'views/epl/request/Index';
import CCRmaEplRequest from 'views/epl/cc_rma/Index';
import CCRmaEplRequestAdd from 'views/epl/cc_rma/add/Add';
import CCRmaEplRequestEdit from 'views/epl/cc_rma/edit/Edit';
import CCRmaEplCreditList from 'views/epl/cc_rma/credit/Index';
import CreditStatus from 'views/epl/credit_status/Index';
import CCRMaEplAddPayment from 'views/epl/cc_rma/payment/Index';
import CCRMaEplPayments from 'views/epl/cc_rma/payment/List';
import PartCatalog from 'views/parts_catalog/Index';
import PartCatalogDetail from 'views/parts_catalog/detail/Index';
import PartCatalogSeller from 'views/parts_catalog/seller/Index';
import OrderRequestForm from 'views/order_request/Index';
import OrderRequestFormUpdate from 'views/order_request/update/Index';
import OrderRequestPreSold from 'views/order_request/presold_update/Index';
import RouteOrderRequest from 'views/route_order_request/Index';
import PartDiscount from 'views/part_discount/Index';
import PartsInfo from 'views/parts/Parts';

import PricingDashboard from 'views/priceManagement/Dashboard';
import Pricing from 'views/priceManagement/Index';
import ProductPrice from 'views/priceManagement/productPrice/Index';
import ProductPriceEdit from 'views/priceManagement/productPrice/Edit';
import ProductPriceAdd from 'views/priceManagement/productPrice/Add';
import CCRMAProductPrice from 'views/priceManagement/cc_rma/Index';
import BannerSetting from 'views/priceManagement/setups/Index';

import Payment from 'views/payments/Index';
import BankDetails from '../views/payments/nigeria/BankSummary';
import MoneyDetails from '../views/payments/nigeria/MoneyTransfer';
import UssdDetails from '../views/payments/nigeria/Ussd';
import PaymentOption from '../views/payments/nigeria/PaymentOptions';
import PaymentTransaction from '../views/payments/TransactionMain';

import Verification from 'views/verification/Index';
import SecondVerification from '../views/verification/SecondVerification';
import VerificationDetails from '../views/verification/secVerifier/Index';
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// ==============================|| MAIN ROUTING ||============================== //
const userRole = localStorage.getItem('userRole');

let page;

if (userRole !== 'Admin') {
    page = <DashboardDefault />;
}

if (userRole === 'Admin') {
    page = <QuickLinks quicklinks={quicklinks} />;
}

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: page
        },
        {
            path: '/report',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/mechanicsettings',
            element: <MechanicSettings />
        },
        {
            path: '/allsalesorders',
            element: <AllTotalSales />
        },
        {
            path: '/momocollections',
            element: <MoMoCollection />
        },
        {
            path: '/allmomocollections',
            element: <AllMoMoCollection />
        },
        {
            path: '/addmomocollection/:id',
            element: <AddMoMoCollection />
        },
        {
            path: '/retailerauditlog/:id',
            element: <RetailerAuditLog />
        },
        {
            path: '/previewmomocollection/:id',
            element: <PreviewMoMoCollection />
        },
        {
            path: '/warehouse',
            element: <WareHouse />
        },
        {
            path: '/addwarehouse',
            element: <AddWareHouse />
        },
        {
            path: '/viewwarehouse/:id',
            element: <ViewWareHouse />
        },
        {
            path: '/salespod',
            element: <SalesPod />
        },

        {
            path: '/salesdrivebulk',
            element: <SalesDriveBulk />
        },
        {
            path: '/addsalesdrivebulk',
            element: <AddSalesDriveBulk />
        },
        {
            path: '/presoldsales',
            element: <Presoldsales />
        },
        {
            path: '/salesdrivebulkshow/:id',
            element: <ShowSalesDriveBulk />
        },

        {
            path: '/salesdrivebulksupdate/:id',
            element: <UpdateSalesDriveBulk />
        },
        {
            path: '/addsalespod',
            element: <AddSalesPod />
        },
        {
            path: '/viewsalespod/:id',
            element: <ViewSalesPod />
        },
        {
            path: '/editsalespod/:id',
            element: <EditSalesPod />
        },
        {
            path: '/addpartsourced',
            element: <PartSourced />
        },
        {
            path: '/fulpartlist',
            element: <InActivePartList />
        },
        {
            path: '/csssalesdriveorders',
            element: <SalesDriveCssOrders />
        },
        {
            path: '/retentionorders',
            element: <RetentionDriveOrders />
        },
        // {
        //     path: '/darkstores',
        //     element: <DarkStoreOrders />
        // },
        {
            path: '/allmessages',
            element: <AllMessages />
        },
        {
            path: '/editinactiveparts/:id',
            element: <EditInActiveParts />
        },
        {
            path: '/fulfilledsalespreview/:id',
            element: <FulfilledPreview />
        },
        {
            path: '/allretailerordersdata',
            element: <AllRetailersOrders />
        },

        {
            path: '/retailerdebittracker',
            element: <RetailerDebitTracker />
        },
        {
            path: '/allsalespersonsales',
            element: <AllSalesPersonSales />
        },
        {
            path: '/auditlogs/:id',
            element: <AuditLogsOrder />
        },
        {
            path: '/allpartssold',
            element: <AllPartsSold />
        },
        {
            path: '/partsrmsold',
            element: <AllGroupPartsSold />
        },
        {
            path: '/orderspartall',
            element: <AllOrdersParts />
        },
        {
            path: '/darkstorearea',
            element: <DarkStoreArea />
        },
        {
            path: '/adddarkstorearea',
            element: <AddDarkStoreArea />
        },
        {
            path: '/viewdarkstorearea/:id',
            element: <ViewDarkStoreArea />
        },

        {
            path: '/batchautomation',
            element: <BatchAutomation />
        },
        {
            path: '/addbatchautomation',
            element: <AddBatchAutomation />
        },
        {
            path: '/viewbatchautomation/:id',
            element: <ViewBatchAutomation />
        },

        {
            path: '/city',
            element: <City />
        },
        {
            path: '/addcity',
            element: <AddCity />
        },
        {
            path: '/editcity/:id',
            element: <EditCity />
        },
        {
            path: '/batchespage',
            element: <BatchesPage />
        },
        {
            path: '/resolvedIssue',
            element: <ResolvedIssue />
        },
        {
            path: '/cssresolvedIssue',
            element: <CSSResolvedOrderIssue />
        },
        {
            path: '/partsuploaddata',
            element: <PartsUploadTest />
        },
        {
            path: '/partsuploadedit/:id',
            element: <PartsUploadEdit />
        },

        {
            path: '/payorder/:id/:referenceid',
            element: <OrderPayment />
        },
        // {
        //     path: '/testbatch',
        //     element: <TestBatch />
        // },
        {
            path: '/roles',
            element: <RolesPage />
        },
        {
            path: '/addRole',
            element: <AddRole />
        },
        {
            path: '/editRole/:id',
            element: <EditRole />
        },

        {
            path: '/viewcssorder/:id',
            element: <ViewCssOrder />
        },

        {
            path: '/attribute',
            element: <Attribute />
        },
        {
            path: '/addattribute',
            element: <AddAttribute />
        },
        {
            path: '/editattribute/:id',
            element: <EditAttribute />
        },

        {
            path: '/carrier',
            element: <CarrierPage />
        },
        {
            path: '/addCarrier',
            element: <AddCarrierPage />
        },

        {
            path: '/editCarrier/:id',
            element: <EditCarrierPage />
        },

        {
            path: '/users',
            element: <UsersPage />
        },
        {
            path: '/addUser',
            element: <AddUsersPage />
        },
        {
            path: '/loyaltylist',
            element: <LoyaltyList />
        },
        {
            path: '/addloyaltylist',
            element: <AddLoyalty />
        },
        {
            path: '/editloyaltylist/:id',
            element: <ViewLoyalty />
        },
        {
            path: '/editUser/:id',
            element: <EditUserPage />
        },
        {
            path: '/channel',
            element: <ChannelPage />
        },
        {
            path: '/addChannel',
            element: <AddChannelPage />
        },
        {
            path: '/editChannel/:id',
            element: <EditChannelPage />
        },
        {
            path: '/orderStatus',
            element: <OrderStatusPage />
        },
        {
            path: '/addOrderStatus',
            element: <AddOrderStatusPage />
        },
        {
            path: '/editOrderStatus/:id',
            element: <EditOrderStatusPage />
        },
        {
            path: '/paymentStatus',
            element: <PaymentStatusPage />
        },
        {
            path: '/addPaymentStatus',
            element: <AddPaymentStatusPage />
        },
        {
            path: '/editPaymentStatus/:id',
            element: <EditPaymentStatusPage />
        },
        { path: '/stagingarea', element: <StagingArea /> },
        { path: '/addstagingarea', element: <AddStagingArea /> },
        { path: '/editstagingarea/:id', element: <ViewStagingArea /> },
        { path: '/retrieveOrderdriver', element: <DriverOrders /> },
        { path: '/adddriverorderimage/:id', element: <AddDriverImage /> },
        { path: '/alldriverdeliveryimages', element: <DriverAllOrders /> },
        { path: '/previewriverorderimage/:id', element: <PreviewDriverImage /> },
        {
            path: '/deliverynoteimages',
            element: <DeliveryNoteImageReport />
        },
        {
            path: '/previewdeliverynote/:id',
            element: <DeliveryNoteImagePreview />
        },
        {
            path: '/dispatchimages',
            element: <DispatchImageReport />
        },
        {
            path: '/previewdispatchorder/:id',
            element: <DispatchImagePreview />
        },

        {
            path: '/partlistnames',
            element: <PartListNames />
        },
        {
            path: '/addpartlistnames',
            element: <AddPartListNames />
        },
        {
            path: '/viewpartlistnames/:id',
            element: <ViewPartListNames />
        },

        {
            path: '/partzones',
            element: <PartZones />
        },
        {
            path: '/addpartzones',
            element: <AddPartZones />
        },
        {
            path: '/pricelists',
            element: <PartPriceListSales />
        },
        {
            path: '/cssorderrequestform',
            element: <CssOrderRequestForm />
        },

        {
            path: '/cspricelist',
            element: <CSPartsPriceList />
        },
        {
            path: '/viewpartzones/:id',
            element: <ViewPartZones />
        },

        {
            path: '/packsortimages',
            element: <PackSortImageReport />
        },
        {
            path: '/previewpacksort/:id',
            element: <PackSortImagePreveiw />
        },
        {
            path: '/confirmdeliverynote/:id',
            element: <NoteConfirmation />
        },

        {
            path: '/partsdata',
            element: <PartsInfo />
        },

        {
            path: '/parts',
            element: <PartsPage />
        },
        {
            path: '/addPart',
            element: <AddPartsPage />
        },
        {
            path: '/editPart/:id',
            element: <EditPartsPage />
        },

        {
            path: '/distributor',
            element: <DistributorPage />
        },
        {
            path: '/addDistributor',
            element: <AddDistributorPage />
        },
        {
            path: '/editDistributor/:id',
            element: <EditDistributorPage />
        },
        {
            path: '/stage',
            element: <Stage />
        },
        {
            path: '/addStage',
            element: <AddStage />
        },
        {
            path: '/editStage/:id',
            element: <EditStage />
        },

        {
            path: '/pickdrop',
            element: <PickDrop />
        },
        {
            path: '/addPickDrop',
            element: <AddPickDrop />
        },
        {
            path: '/editPickDrop/:id',
            element: <EditPickDrop />
        },

        {
            path: '/ordertype',
            element: <OrderType />
        },
        {
            path: '/addOrderType',
            element: <AddOrderType />
        },
        {
            path: '/editOrderType/:id',
            element: <EditOrderType />
        },

        {
            path: '/retail',
            element: <Retailer />
        },
        {
            path: '/addRetailer',
            element: <AddRetailer />
        },
        {
            path: '/editRetailer/:id',
            element: <EditRetailer />
        },

        {
            path: '/mechanic',
            element: <Mechanic />
        },
        {
            path: '/addMechanic',
            element: <AddMechanic />
        },
        {
            path: '/editMechanic/:id',
            element: <EditMechanic />
        },
        {
            path: '/order',
            element: <Order />
        },
        {
            path: '/addOrder',
            element: <AddOrder />
        },
        {
            path: '/viewOrder/:id/:rma',
            element: <ViewOrder />
        },
        {
            path: '/showOrder/:id',
            element: <ShowOrder />
        },

        {
            path: '/contactorder',
            element: <ContactOrder />
        },
        {
            path: '/addContactOrder',
            element: <AddContactOrder />
        },
        {
            path: '/editContactOrder/:id',
            element: <EditContactOrder />
        },
        {
            path: '/car',
            element: <Car />
        },
        {
            path: '/addCar',
            element: <AddCar />
        },
        {
            path: '/editCar/:id',
            element: <EditCar />
        },
        {
            path: '/carmodel',
            element: <CarModel />
        },
        {
            path: '/addCarModel',
            element: <AddCarModel />
        },
        {
            path: '/editCarModel/:id',
            element: <EditCarModel />
        },
        {
            path: '/policyid',
            element: <PolicyId />
        },
        {
            path: '/addPolicyId',
            element: <AddPolicyId />
        },
        {
            path: '/editPolicyId/:id',
            element: <EditPolicyId />
        },
        {
            path: '/servicefee',
            element: <ServiceFee />
        },
        {
            path: '/addServiceFee',
            element: <AddServiceFee />
        },
        {
            path: '/editServiceFee/:id',
            element: <EditServiceFee />
        },
        {
            path: '/orderparts',
            element: <OrderParts />
        },
        {
            path: '/addOrderParts',
            element: <AddOrderParts />
        },
        {
            path: '/editOrderParts/:id',
            element: <ViewOrderParts />
        },
        {
            path: '/requestservice',
            element: <RequestService />
        },
        {
            path: '/addRequestService',
            element: <AddRequestService />
        },
        {
            path: '/editRequest/:id',
            element: <EditRequest />
        },
        {
            path: '/service',
            element: <Service />
        },
        {
            path: '/addService',
            element: <AddService />
        },
        {
            path: '/editService/:id',
            element: <ViewService />
        },
        {
            path: '/requestdiagnostics',
            element: <RequestDiagnostics />
        },
        {
            path: '/addrequestdiagnostics',
            element: <AddRequestDiagnostics />
        },
        {
            path: '/viewrequestdiagnostics/:id',
            element: <ViewRequestDiagnostics />
        },
        {
            path: '/claimId',
            element: <ClaimId />
        },
        {
            path: '/addClaimId',
            element: <AddClaimId />
        },
        {
            path: '/viewClaimId/:id',
            element: <ViewClaimId />
        },
        {
            path: '/claimTypes',
            element: <ClaimTypes />
        },
        {
            path: '/addClaimTypes',
            element: <AddClaimTypes />
        },
        {
            path: '/viewClaimTypes/:id',
            element: <ViewClaimTypes />
        },
        {
            path: '/comparePrice',
            element: <ComparePrices />
        },
        {
            path: '/addComparePrice',
            element: <AddComparePrice />
        },
        {
            path: '/viewComparePrice/:id',
            element: <ViewComparePrices />
        },
        {
            path: '/salesteam',
            element: <SalesTeam />
        },
        {
            path: '/addsalesteam',
            element: <AddSalesTeam />
        },
        {
            path: '/viewsalesteam/:id',
            element: <ViewSalesTeam />
        },
        {
            path: '/customersuccessteam',
            element: <CustomerSuccessTeam />
        },
        {
            path: '/addcustomersuccessteam',
            element: <AddCustomerSuccessTeam />
        },
        {
            path: '/viewcustomersuccessteam/:id',
            element: <ViewCustomerSuccessTeam />
        },
        {
            path: '/mechanicpartners',
            element: <MechanicPartners />
        },
        {
            path: '/retailpartners',
            element: <RetailerPartners />
        },
        {
            path: '/transactions',
            element: <Transactions />
        },
        {
            path: '/newretailerinfor',
            element: <NewRetailer />
        },
        {
            path: '/viewtransactions/:id',
            element: <ViewTransactions />
        },
        {
            path: '/pricechanges/:id',
            element: <PriceChanges />
        },
        {
            path: '/enterpriseform',
            element: <EnterPriseMotorAccident />
        },
        {
            path: '/lotdetails',
            element: <LotDetails />
        },
        {
            path: '/addlotdetails',
            element: <AddLotDetails />
        },
        {
            path: '/fleetservice',
            element: <FleetService />
        },
        {
            path: '/addfleetservice',
            element: <AddFleetService />
        },
        {
            path: '/viewfleetservice/:id',
            element: <ViewFleetService />
        },
        {
            path: '/sales',
            element: <Sales />
        },

        {
            path: '/newlead',
            element: <Lead />
        },
        {
            path: '/ccnewlead',
            element: <CCLead />
        },
        {
            path: '/fleetservicefee',
            element: <FleetServiceFee />
        },
        {
            path: '/addfleetservicefee',
            element: <AddFleetServiceFee />
        },
        {
            path: '/viewfleetservicefee/:id',
            element: <ViewFleetServiceFee />
        },
        {
            path: '/fleetserviceid',
            element: <FleetServiceId />
        },
        {
            path: '/addfleetserviceid',
            element: <AddFleetServiceId />
        },
        {
            path: '/viewfleetserviceidsinfo/:id',
            element: <ViewFleetServiceId />
        },
        {
            path: '/fleetpartner',
            element: <FleetPartner />
        },
        {
            path: '/addfleetpartner',
            element: <AddFleetPartner />
        },
        {
            path: '/viewfleetpartner/:id',
            element: <ViewFleetPartner />
        },
        {
            path: '/fleetorderparts',
            element: <FleetOrderParts />
        },
        {
            path: '/channelpartnerfleetorderparts',
            element: <MyFleetOrderParts />
        },
        {
            path: '/addfleetorderparts',
            element: <AddFleetOrderParts />
        },
        {
            path: '/addmyfleetorderparts',
            element: <AddMyFleetOrderParts />
        },
        {
            path: '/viewfleetorderparts/:id',
            element: <ViewFleetOrderParts />
        },
        {
            path: '/viewmyfleetorderparts/:id',
            element: <ViewMyFleetOrderParts />
        },
        {
            path: '/showfleetorderparts/:id',
            element: <ShowFleetOrderParts />
        },
        {
            path: '/showmyfleetorderparts/:id',
            element: <ShowMyFleetOrderParts />
        },
        {
            path: '/emissions',
            element: <Emissions />
        },
        {
            path: '/inventory',
            element: <Inventory />
        },
        {
            path: '/addinventory',
            element: <AddInventory />
        },
        {
            path: '/stockadjustment',
            element: <StockAdjustment />
        },
        {
            path: '/updateinventory/:id',
            element: <UpdateInventory />
        },
        {
            path: '/showinventory/:id',
            element: <ShowInventory />
        },

        {
            path: '/showDistributorinventory/:id',
            element: <ShowDistributorInventory />
        },
        {
            path: '/inspectionreport',
            element: <InspectionReport />
        },
        {
            path: '/addinspectionreport',
            element: <AddInspectionReport />
        },
        {
            path: '/viewinspectionreport/:id',
            element: <ViewInspectionReport />
        },
        {
            path: '/fleetsettings',
            element: <FleetSettings />
        },
        {
            path: '/fleetrequestservice',
            element: <FleetRequestService />
        },
        {
            path: '/addfleetrequestservice',
            element: <AddFleetRequestService />
        },
        {
            path: '/viewfleetrequestservice/:id',
            element: <ViewFleetRequestService />
        },
        {
            path: '/showfleetrequestservice/:id',
            element: <ShowFleetRequestService />
        },
        {
            path: '/garagefleetrequestservice',
            element: <GarageFleetRequestService />
        },
        {
            path: '/showgaragefleetrequestservice/:id',
            element: <ShowGarageFleetRequestService />
        },
        {
            path: '/fleetdriverorderparts',
            element: <FleetDriverOrderParts />
        },
        {
            path: '/addfleetdriverorderparts',
            element: <AddFleetDriverOrderParts />
        },
        {
            path: '/viewfleetdriverorderparts/:id',
            element: <ViewFleetDriverOrderParts />
        },
        // {
        //     path: '/showfleetdriverorderparts/:id',
        //     element: <ShowFleetDriverOrderParts />
        // },
        {
            path: '/fleetemailorder',
            element: <FleetEmailOrder />
        },
        {
            path: '/addfleetemailorder',
            element: <AddFleetEmailOrder />
        },
        {
            path: '/viewfleetemailorder/:id',
            element: <ViewFleetEmailOrder />
        },
        {
            path: '/fleetaccount',
            element: <FleetAccount />
        },
        {
            path: '/addfleetaccount',
            element: <AddFleetAccount />
        },
        {
            path: '/country',
            element: <Country />
        },
        {
            path: '/addcountry',
            element: <AddCountry />
        },
        {
            path: '/viewcountry/:id',
            element: <ViewCountry />
        },
        {
            path: '/servicestatus',
            element: <ServiceStatus />
        },
        {
            path: '/addservicestatus',
            element: <AddServiceStatus />
        },
        {
            path: '/viewservicestatus/:id',
            element: <ViewServiceStatus />
        },

        {
            path: '/tags',
            element: <TagPage />
        },
        {
            path: '/addtags',
            element: <AddTagPage />
        },
        {
            path: '/edittag/:id',
            element: <EditTagPage />
        },

        {
            path: '/fleetdriverrequestservice',
            element: <FleetDriverRequestService />
        },
        {
            path: '/addfleetdriverrequestservice',
            element: <FleetDriverAddRequestService />
        },
        {
            path: '/viewfleetdriverrequestservice/:id',
            element: <FleetDriverViewRequestService />
        },
        {
            path: '/mysalesorder',
            element: <MySalesOrder />
        },

        {
            path: '/presoldmysalesorder',
            element: <RMAPresoldOrders />
        },
        {
            path: '/presoldpodorder',
            element: <PodPresoldOrders />
        },
        {
            path: '/viewmysalesorders/:id',
            element: <OrderRequestFormUpdate />
        },
        {
            path: '/viewmypresoldorders/:id',
            element: <ViewPreSoldOrders />
        },

        {
            path: '/previewmysalesorders/:id',
            element: <PreviewMySalesOrder />
        },
        {
            path: '/partsgarage',
            element: <PartsGarage />
        },
        {
            path: '/addpartsgarage',
            element: <AddPartsGarage />
        },
        {
            path: '/viewpartsgarage/:id',
            element: <ViewPartsGarage />
        },
        {
            path: '/orderissues',
            element: <IssuesOrder />
        },
        {
            path: '/cssorderissues',
            element: <CssOrderIssue />
        },
        {
            path: '/correctorderdata',
            element: <CorrectOrder />
        },
        {
            path: '/proformacssorders',
            element: <ProformCssOrders />
        },

        {
            path: '/presoldorders',
            element: <PresoldOrders />
        },

        {
            path: '/conversionorders',
            element: <ConversionOrders />
        },

        {
            path: '/salespartner',
            element: <SalesPartner />
        },
        {
            path: '/viewsalespartner/:id',
            element: <ViewSalesPartner />
        },
        {
            path: '/addshop/:id',
            element: <AddShopPartner />
        },
        {
            path: '/addordertag/:id',
            element: <AddOrderTag />
        },
        {
            path: '/addissue/:id',
            element: <AddIssuesOrder />
        },
        {
            path: '/previewsalespartner/:id',
            element: <PreviewSalesPartner />
        },
        {
            path: '/garagevinsearch',
            element: <VinSearch />
        },
        {
            path: '/neworder',
            element: <NewOrder />
        },
        {
            path: '/addneworder',
            element: <AddNewOrder />
        },
        {
            path: '/viewneworder',
            element: <ViewNewOrder />
        },
        {
            path: '/leademail',
            element: <LeadEmail />
        },
        {
            path: '/addleademail',
            element: <AddLeadEmail />
        },

        {
            path: '/viewleademail/:id',
            element: <EditLeadEmail />
        },
        {
            path: '/ordertablestatus',
            element: <OrderTableStatus />
        },
        {
            path: '/messagegroups',
            element: <MessageGroups />
        },

        {
            path: '/lead',
            element: <NewLead />
        },
        {
            path: '/retailerOrderHistory/:id',
            element: <RetailerOrderHistory />
        },
        {
            path: '/retailerdebitOrderHistory/:id',
            element: <RetailerDebitHistory />
        },

        {
            path: '/viewretailerdebittracker/:id',
            element: <ViewRetailerDebitTracker />
        },

        {
            path: '/viewRetailerOrderHistory/:id',
            element: <ViewRetailerOrderHistory />
        },
        {
            path: '/ccupdateorder/:id/:rma',
            element: <CCUpdateOrder />
        },
        {
            path: '/dashboardRetailerIndex',
            element: <DashboardRetailerIndex />
        },
        {
            path: '/addcollections/:id',
            element: <AddCollection />
        },
        {
            path: '/collectionemail',
            element: <CollectionEmail />
        },
        {
            path: '/addcollectionemail',
            element: <AddCollectionEmail />
        },
        {
            path: '/editcollectionemail/:id',
            element: <EditCollectionEmail />
        },
        {
            path: '/distributorInventory',
            element: <DistributorInventory />
        },
        {
            path: '/addDistributorInventory',
            element: <AddIDistributornventory />
        },
        {
            path: '/stockInventoryDistributor',
            element: <StockInventoryDistributor />
        },
        {
            path: '/distributorinventoryorders',
            element: <InventoryOrdersDistributor />
        },
        {
            path: '/soldPartsDistributor',
            element: <SoldPartsDistributor />
        },
        {
            path: '/collectionorders',
            element: <CollectionOrders />
        },
        {
            path: '/viewcollectionorders/:id',
            element: <ViewCollectionOrders />
        },

        {
            path: '/newretailers',
            element: <NewRetailers />
        },
        {
            path: '/orderlist',
            element: <OrderList />
        },
        {
            path: '/depositedorderlist',
            element: <DepositedOrders />
        },
        {
            path: '/viewdepositedorder/:id',
            element: <ViewDepositedOrders />
        },
        {
            path: '/jobcard',
            element: <Jobcard />
        },

        {
            path: '/customercomplaint',
            element: <CustomerComplaint />
        },
        {
            path: '/addcustomercomplaint',
            element: <AddCustomerComplaint />
        },
        {
            path: '/viewcustomercomplaint/:id',
            element: <ViewCustomerComplaint />
        },
        {
            path: '/issuesdiagnosed',
            element: <IssueDiagnosed />
        },
        {
            path: '/addissuediagnosed',
            element: <AddIssueDiagnosed />
        },
        {
            path: '/viewissuediagnosed/:id',
            element: <ViewIssueDiagnosed />
        },
        {
            path: '/addjobcard',
            element: <AddJobCard />
        },
        {
            path: '/viewjobcard/:id',
            element: <ViewJobCard />
        },
        {
            path: '/showjobcard/:id',
            element: <ShowJobCard />
        },
        {
            path: '/customer',
            element: <Customer />
        },
        {
            path: '/addcustomer',
            element: <AddCustomer />
        },
        {
            path: '/editcustomer/:id',
            element: <EditCustomer />
        },
        {
            path: '/JobOrder',
            element: <JobOrder />
        },
        {
            path: '/UpdateJobOrder/:id',
            element: <UpdateJobOrder />
        },
        {
            path: '/ViewJobOrder/:id',
            element: <ViewJobOrder />
        },
        {
            path: '/autoaccount',
            element: <AutoAccount />
        },
        {
            path: '/addautoaccount',
            element: <AddAutoAccount />
        },
        {
            path: '/customersatisfaction',
            element: <CustomerSatisfaction />
        },
        {
            path: '/customersuccessdashboard',
            element: <CustomerSuccessDashboard />
        },
        {
            path: '/distributordashboard',
            element: <DistributorDashboard />
        },
        {
            path: '/fleetvehiclerequest',
            element: <FleetVehicleRequest />
        },
        {
            path: '/addfleetvehiclerequest',
            element: <AddFleetVehicleRequest />
        },
        {
            path: '/viewfleetvehiclerequest/:id',
            element: <ViewFleetVehicleRequest />
        },
        {
            path: '/showfleetvehiclerequest/:id',
            element: <ShowFleetVehicleRequest />
        },
        {
            path: '/ApproveFleetVehicleRequest',
            element: <ApproveFleetVehicleRequest />
        },
        {
            path: '/approvevehiclerequestcss/:id',
            element: <ApproveCssVehicleRequest />
        },
        {
            path: '/viewvehiclerequestcss/:id',
            element: <ViewCssVehicleRequest />
        },

        {
            path: '/approvelinkdeposit/:id',
            element: <ApproveLink />
        },
        // {
        //     path: '/approvelinkdepositng/:id',
        //     element: <ApproveLinkNG />
        // },
        {
            path: '/requestservicingfleet',
            element: <RequestServicingFleet />
        },
        {
            path: '/allrequestservicingfleet',
            element: <AllRequestServicingFleet />
        },
        {
            path: '/updateallrequestservicingfleet/:id',
            element: <AllUpdateRequestServicingFleet />
        },
        {
            path: '/allviewingrequestservicingfleet/:id',
            element: <AllViewingRequestServicingFleet />
        },

        {
            path: '/addrequestservicingfleet',
            element: <AddRequestServicingFleet />
        },
        {
            path: '/updaterequestservicingfleet/:id',
            element: <UpdateRequestServicingFleet />
        },
        {
            path: '/viewrequestservicingfleet/:id',
            element: <ViewRequestServicingFleet />
        },
        {
            path: '/calendarrequestservicing',
            element: <CalendarRequestServicing />
        },
        {
            path: '/cssOrders',
            element: <CssOrders />
        },

        {
            path: '/bulkvehicleupload',
            element: <BulkVehicleUpload />
        },
        {
            path: '/fleetcar',
            element: <FleetCar />
        },
        {
            path: '/addfleetcar',
            element: <AddFleetCar />
        },
        {
            path: '/editfleetcar/:id',
            element: <EditFleetCar />
        },
        {
            path: '/fleetcarmodel',
            element: <FleetCarModel />
        },
        {
            path: '/addfleetcarmodel',
            element: <AddFleetCarModel />
        },
        {
            path: '/editfleetcarmodel/:id',
            element: <EditFleetCarModel />
        },
        {
            path: '/allfleetpart',
            element: <FleetPart />
        },
        {
            path: '/addfleetpart',
            element: <AddFleetPart />
        },
        {
            path: '/viewfleetparts/:id',
            element: <ViewFleetPart />
        },
        {
            path: '/partorderedfleet',
            element: <PartsOrderedFleet />
        },
        {
            path: '/addpartorderedfleet',
            element: <AddPartsOrderedFleet />
        },
        {
            path: '/partsordersfleet',
            element: <PartOrdersFleetCss />
        },
        {
            path: '/orderpartsfleetlist',
            element: <OrderPartsFleetList />
        },
        {
            path: '/updateorderpartsfleet/:id',
            element: <UpdatePartsOrderFleet />
        },
        {
            path: '/partsordersfleetupdate/:id',
            element: <UpdatePartOrderCssFleet />
        },
        {
            path: '/bulkuploadfleetpartner/:id',
            element: <BulkUploadFleetPartner />
        },
        {
            path: '/orderreport',
            element: <OrderReport />
        },
        {
            path: '/collectionreport',
            element: <CollectionReport />
        },
        {
            path: '/depositreport',
            element: <DepositReport />
        },
        // {
        //     path: '/unapproveddepositreport',
        //     element: <UnApprovedDepositReport />
        // },
        {
            path: '/salescollectionreport',
            element: <SalesCollectionReport />
        },
        {
            path: '/salesdepositedreport',
            element: <SalesDepositedReport />
        },
        {
            path: '/fleetindividualpartner',
            element: <FleetPartnerIndividual />
        },
        {
            path: '/addfleetindividualpartner',
            element: <AddFleetIndividualPartner />
        },
        {
            path: '/viewfleetindividualpartner/:id',
            element: <ViewFleetIndividualPartner />
        },
        {
            path: '/addindividualfleetrequest/:id',
            element: <AddIndividualFleetVehicleRequest />
        },
        {
            path: '/fleetmanagerreminder',
            element: <FleetManagerReminder />
        },
        {
            path: '/addfleetmanagerreminder',
            element: <AddFleetManagerReminder />
        },
        {
            path: '/editfleetmanagerreminder/:id',
            element: <EditFleetManagerReminder />
        },
        {
            path: '/showfleetmanagerreminder/:id',
            element: <ShowFleetManagerReminder />
        },
        {
            path: '/allfleetmanagerreminder',
            element: <AllFleetManagerReminder />
        },
        {
            path: '/viewfleetmanagerreminder/:id',
            element: <ViewAllFleetReminder />
        },
        {
            path: '/addgaragereminder/:id',
            element: <AddGarageReminder />
        },
        {
            path: '/allgaragereminder',
            element: <AllGarageManagerReminder />
        },
        {
            path: '/vehicleprofile/:id',
            element: <VehicleProfile />
        },
        {
            path: '/fleetvehicleprofile/:id',
            element: <FleetVehicleProfile />
        },
        {
            path: '/garagecalender',
            element: <GarageManagerCalender />
        },
        {
            path: '/jobcardfleet',
            element: <JobCardFleet />
        },
        {
            path: '/addjobcardfleet/:id',
            element: <AddJobCardFleet />
        },
        {
            path: '/viewjobcardfleet/:id',
            element: <ViewJobCardFleet />
        },
        {
            path: '/previewjobcardfleet/:id',
            element: <PreviewJobCardFleet />
        },
        {
            path: '/adddeliveryimage/:id',
            element: <AddDeliveryImage />
        },
        {
            path: '/adddispatchimage/:id',
            element: <AddDispatchImage />
        },
        {
            path: '/addpacksortimage/:id',
            element: <AddPackSortImage />
        },
        {
            path: '/adddeliveryimagedata/:id',
            element: <AddDeliveryImage2 />
        },
        {
            path: '/adddispatchimagedata/:id',
            element: <AddDispatchImage2 />
        },
        {
            path: '/addpacksortimagedata/:id',
            element: <AddPackSortImage2 />
        },
        {
            path: '/updateproformaorder/:id',
            element: <ProformaOrderUpdateCss />
        },
        {
            path: '/targetlist',
            element: <Targets />
        },
        {
            path: '/addtarget',
            element: <AddTargets />
        },
        {
            path: '/viewtarget/:id',
            element: <ViewTargets />
        },
        {
            path: '/todolist',
            element: <TodoList />
        },
        {
            path: '/addtodolist',
            element: <AddTodoList />
        },
        {
            path: '/viewtodolist/:id',
            element: <ViewTodoList />
        },
        {
            path: '/batchstatus',
            element: <BatchStatus />
        },
        {
            path: '/addbatchstatus',
            element: <AddBatchStatus />
        },
        {
            path: '/viewbatchstatus/:id',
            element: <ViewBatchStatus />
        },
        {
            path: '/order-batches/:id',
            element: <OrderBatchView />
        },
        {
            path: '/order-priority',
            element: <OrderPriority />
        },
        {
            path: '/orders/deliveries',
            element: <DeliveryNote />
        },
        {
            path: '/orders/deliveries/notes',
            element: <AllNotes />
        },
        {
            path: '/orders/deliveries/:id',
            element: <SingleOrder />
        },
        {
            path: '/sales/deliveries',
            element: <SalePersonDelivery />
        },
        {
            path: '/retailers/shops',
            element: <RetailerShop />
        },
        {
            path: '/sales/new',
            element: <OrderRequest />
        },
        {
            path: '/retailers/loyalties/point',
            element: <Loyalty />
        },

        {
            path: '/orders/notes/update/:id',
            element: <UpdateNote />
        },
        {
            path: '/inventory/darkstores/',
            element: <DarkStore />
        },
        {
            path: '/inventory/darkstores/:id',
            element: <StoreInventory />
        },
        {
            path: '/inventory/darkstores/history/:productId',
            element: <StockHistory />
        },
        {
            path: '/suppliers/batch',
            element: <SupplierBatch />
        },
        {
            path: '/suppliers/batch/:id',
            element: <BatchOrders />
        },
        {
            path: '/suppliers/batch/submit/:id',
            element: <PlaceBatchOrder />
        },
        {
            path: '/analytics/data',
            element: <DataPortal />
        },
        {
            path: '/priorityautomation',
            element: <PriorityAutomation />
        },
        {
            path: '/addpriorityautomation',
            element: <AddPriorityAutomation />
        },
        {
            path: '/editpriorityautomation/:id',
            element: <EditPriorityAutomation />
        },
        {
            path: '/map',
            element: <Map />
        },
        {
            path: '/permission',
            element: <Permission />
        },
        {
            path: '/retailer-gender',
            element: <Gender />
        },
        {
            path: '/image-upload',
            element: <ImageUpload />
        },
        {
            path: '/check-in',
            element: <CheckIn />
        },
        {
            path: '/checkins',
            element: <ViewCheckIn />
        },
        {
            path: '/logistics',
            element: <Logistics />
        },
        {
            path: '/assign-order',
            element: <AssignOrder />
        },
        {
            path: '/order-details/:id',
            element: <OrderDetails />
        },
        {
            path: '/tracking',
            element: <Tracking />
        },
        {
            path: '/salesplanning',
            element: <SalesPlan />
        },
        {
            path: '/rma/salesplanning',
            element: <SalesPlan />
        },
        {
            path: '/addsalesplanning/:id',
            element: <SalesPlanCreate />
        },
        {
            path: '/salesplan/edit/:id',
            element: <EditSalesPlan />
        },
        {
            path: '/salesplan/bulk-edit',
            element: <BulkEditSalesPlan />
        },
        {
            path: '/salesplan/drivetype',
            element: <SalesDriveType />
        },
        {
            path: '/salesplan/location',
            element: <SalesDriveLocation />
        },
        {
            path: '/fleet',
            element: <FleetAllocation />
        },
        {
            path: '/assign-fleet/:id',
            element: <AssignFleet />
        },
        {
            path: '/fleet/fleet-tracking/:id',
            element: <TrackingFleet />
        },
        {
            path: '/fleet/edit-fleet/:id',
            element: <EditFleetForm />
        },
        {
            path: '/kpi',
            element: <ViewKPI />
        },
        {
            path: '/kpi/set-kpi',
            element: <SetKPI />
        },
        {
            path: '/kpi/view-kpi/:id',
            element: <SingleKPI />
        },
        {
            path: '/kpi/edit-kpi/:id',
            element: <EditKPI />
        },
        {
            path: '/waybill/orders',
            element: <WayBill />
        },
        {
            path: '/waybill/generate-waybill/:id',
            element: <CreateWayBill />
        },
        {
            path: '/edit-waybill/:id',
            element: <EditWayBill />
        },
        {
            path: '/waybill/generated/:id',
            element: <ViewWayBill />
        },
        {
            path: '/waybill/batch',
            element: <Batch />
        },
        {
            path: '/edit-batch/:id',
            element: <EditBatch />
        },
        {
            path: '/waybill/batch/:id',
            element: <SingleBatch />
        },
        {
            path: '/generated/waybill',
            element: <GeneratedWayBill />
        },
        {
            path: '/waybill/waybill-details/:id',
            element: <SingleGeneratedWayBill />
        },
        {
            path: '/waybill/print/:id',
            element: <PrintWayBill />
        },
        {
            path: '/track-all',
            element: <TrackingAllDrivers />
        },
        {
            path: '/fleet/add-vehicles',
            element: <AddVehicles />
        },
        {
            path: '/fleet/all-vehicles',
            element: <AllGarageVehicles />
        },
        {
            path: '/cohort',
            element: <Corhot />
        },
        {
            path: '/growthview',
            element: <GrowthView />
        },
        {
            path: '/clearancesheet',
            element: <ClearanceSheet />
        },
        {
            path: '/collection/paymentstatus',
            element: <CollectionPaymentStatus />
        },
        {
            path: '/pre-sold-orders',
            element: <Presold />
        },
        {
            path: '/pre-sold-orders/:id',
            element: <PresoldSingleBatch />
        },
        {
            path: '/pre-sold-orders/batch',
            element: <PresoldBatch />
        },
        {
            path: '/retailers/profile',
            element: <RetailerProfile />
        },
        {
            path: '/retailers/order-history/:id',
            element: <RetailersOrderHistory />
        },
        {
            path: '/retailers/analytics/:id',
            element: <RetailerAnalytics />
        },
        {
            path: '/retailers/map',
            element: <RetailerMap />
        },
        {
            path: '/retailers/map/:id',
            element: <RetailerProfileMap />
        },
        {
            path: '/retailers/report/:id',
            element: <RetailerReport />
        },
        {
            path: '/retailer/profile/:id',
            element: <SingleRetailerProfile />
        },
        {
            path: '/retailers/notes/:id',
            element: <Notes />
        },
        {
            path: '/retailers/tasks',
            element: <AssignTasks />
        },
        {
            path: '/retailers/reminders/:id',
            element: <Reminders />
        },
        {
            path: '/retailers/myretailers',
            element: <RmaRetailerDashboard />
        },
        {
            path: '/retailers/allnotes',
            element: <AllRetailerNotes />
        },
        {
            path: '/tasks/mytasks',
            element: <RmaCcTasks />
        },
        {
            path: '/cssplacedorders',
            element: <CssPlacedOrder />
        },
        {
            path: '/salescrm',
            element: <CRM />
        },
        {
            path: '/salescrm/:id',
            element: <SingleCRM />
        },
        {
            path: '/salescrm/analysis',
            element: <CrmAnalytics />
        },
        {
            path: '/salescrm/all-notes/:id',
            element: <CrmNotes />
        },
        {
            path: '/retailer-loyalty',
            element: <RetailerLoyalty />
        },
        {
            path: '/retailer-loyalty/:id',
            element: <RetailerLoyaltyDetail />
        },
        {
            path: '/retailer-loyalty/summary',
            element: <RetailerLoyaltySummary />
        },
        {
            path: '/retailer-loyalty/rewards',
            element: <RetailerLoyaltyRewards />
        },
        {
            path: '/orders/allreceiptpayments',
            element: <AllPayments />
        },
        {
            path: '/rmcc/sleepover',
            element: <SleepOver />
        },
        {
            path: 'adminorderrequestform',
            element: <AdminOrderRequestForm />
        },
        {
            path: '/rma-route-schedule',
            element: <RmaRouteDashboard />
        },
        {
            path: '/rma-route-schedule-report',
            element: <RmaRouteReport />
        },
        {
            path: '/rma-route-schedule-retailers',
            element: <RmaRouteRetailers />
        },
        {
            path: '/admin-route-schedule',
            element: <AdminRouteDashboard />
        },
        {
            path: '/admin-route-schedule-report/:id',
            element: <AdminRouteReport />
        },
        {
            path: '/mira/inbox-messages',
            element: <MiraInbox />
        },
        {
            path: '/epl/configuration',
            element: <EPLConfiguration />
        },
        {
            path: '/epl/easy-pay-later',
            element: <EasyPay />
        },
        {
            path: '/epl/credit-add',
            element: <AddEasyPayCredit />
        },
        {
            path: '/epl/credit-edit/:id',
            element: <EditEasyPayCredit />
        },
        {
            path: '/epl/add-payment',
            element: <AddEasyPayPayment />
        },
        {
            path: '/epl/edit-payment/:id',
            element: <EditEasyPayPayment />
        },
        {
            path: '/epl/easy-pay-summary',
            element: <EplSummary />
        },
        {
            path: '/epl/easy-pay-request',
            element: <EplRequest />
        },
        {
            path: '/epl/cc-rma-request',
            element: <CCRmaEplRequest />
        },
        {
            path: '/epl/cc-rma-request-add/:id',
            element: <CCRmaEplRequestAdd />
        },
        {
            path: '/epl/cc-rma-request-edit/:id',
            element: <CCRmaEplRequestEdit />
        },
        {
            path: '/epl/cc-rma-credit-status',
            element: <CCRmaEplCreditList />
        },
        {
            path: '/epl/credit_status',
            element: <CreditStatus />
        },
        {
            path: '/epl/cc_rma/add-payment/:id',
            element: <CCRMaEplAddPayment />
        },
        {
            path: '/epl/cc_rma/payments',
            element: <CCRMaEplPayments />
        },
        {
            path: '/parts/discount/:id',
            element: <ViewPartsDiscount />
        },
        {
            path: '/parts/discount/add/:id',
            element: <AddPartsDiscount />
        },
        {
            path: '/parts/discount/edit/:id',
            element: <EditPartsDiscount />
        },
        {
            path: '/parts-catalog',
            element: <PartCatalog />
        },
        {
            path: '/parts-catalog/:id',
            element: <PartCatalogDetail />
        },
        {
            path: '/parts-catalog/seller-prices/:id',
            element: <PartCatalogSeller />
        },
        {
            path: '/order-request-form',
            element: <OrderRequestForm />
        },
        {
            path: '/order-request-form/:id',
            element: <OrderRequestFormUpdate />
        },
        {
            path: '/order-request-form-pre-sold/:id',
            element: <OrderRequestPreSold />
        },
        {
            path: '/route-order-request-form/:id',
            element: <RouteOrderRequest />
        },
        {
            path: '/part-discount',
            element: <PartDiscount />
        },
        {
            path: '/pricing-dashboard',
            element: <PricingDashboard />
        },
        {
            path: '/pricing-parts',
            element: <Pricing />
        },
        {
            path: '/product-pricing/:id',
            element: <ProductPrice />
        },
        {
            path: '/product-pricing/edit/:id',
            element: <ProductPriceEdit />
        },
        {
            path: '/product-pricing/add/:id',
            element: <ProductPriceAdd />
        },
        {
            path: '/cc_rma/product-pricing',
            element: <CCRMAProductPrice />
        },
        {
            path: '/pricing-banner-setups',
            element: <BannerSetting />
        },
        {
            path: '/payment-initiation/:id',
            element: <Payment />
        },
        {
            path: '/bank/payment-summary',
            element: <BankDetails />
        },
        {
            path: '/money/payment-summary/',
            element: <MoneyDetails />
        },
        {
            path: '/ussd/payment-summary',
            element: <UssdDetails />
        },
        {
            path: '/payment-options',
            element: <PaymentOption />
        },
        {
            path: '/payment-transactions',
            element: <PaymentTransaction />
        },
        {
            path: '/verification',
            element: <Verification />
        },
        {
            path: '/verification/second-stage',
            element: <SecondVerification />
        },
        {
            path: '/verification/second-stage/:id',
            element: <VerificationDetails />
        }
    ]
};

export default MainRoutes;
