import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Input, Select, DatePicker, Modal, Checkbox, Divider, Table, Radio } from 'antd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import ApiUrl from '../../../network';

export const Index = () => {
    const date = new Date();
    const today = dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const { id } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const orderTypeUrl = searchParams.get('orderType');
    const { RangePicker } = DatePicker;
    const [loading, setLoading] = useState(false);
    const [ordersModal, setOrdersModal] = useState(false);
    const [orders, setOrders] = useState([]);
    const [orderQuantity, setOrderQuantity] = useState(0);
    const [orderPick, setOrderPick] = useState([]);
    const [showOrderPick, setShowOrderPick] = useState(false);
    const [orderType, setOrderType] = useState('');
    const [driverName, setDriverName] = useState('');
    const [carNo, setCarNo] = useState('');
    const [orderInfo, setOrderInfo] = useState([]);
    const [batchStatusId, setBatchStatusId] = useState('');
    const [batchDate, setBatchDate] = useState(today);
    const [batchStatus, setBatchStatus] = useState([]);
    const [driverList, setDriverList] = useState([]);
    const [carList, setCarList] = useState([]);
    const [checkedOrders, setCheckedOrders] = useState([]);
    const [order, setOrder] = useState({});
    const [orderModalVisible, setOrderModalVisible] = useState(false);
    const [productModalVisible, setProductModalVisible] = useState(false);
    const [itemName, setItemName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [orderItemSelect, setOrderItemSelect] = useState({});
    const [productListSelect, setProductListSelect] = useState([
        {
            id: '',
            key: '',
            partId: '',
            qty: null
        }
    ]);
    const [products, setProducts] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [orderNo, setOrderNo] = useState('');
    const [batchData, setBatchData] = useState({});
    const [thirdPartyDriver, setThirdPartyDriver] = useState('');

    const editOrder = (order) => {
        setOrderItemSelect(order);
        setItemName(order.partid);
        setQuantity(order.qty);
        setOrderModalVisible(true);
    };

    const handleDelete = (id) => {
        const newOrderDetails = order?.items?.filter((item) => item._id !== id);
        setOrder({ ...order, items: newOrderDetails });
    };

    const columns = [
        {
            title: 'Product name',
            dataIndex: 'partid',
            key: 'partid'
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <div className="d-flex">
                    <Button
                        type="primary"
                        onClick={() => {
                            editOrder(record);
                        }}
                        style={{
                            marginRight: '10px',
                            backgroundColor: '#fff',
                            borderColor: '#FF740F',
                            color: '#FF740F'
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleDelete(record._id);
                        }}
                        style={{
                            marginRight: '10px',
                            backgroundColor: '#fff',
                            borderColor: '#FF740F',
                            color: '#FF740F'
                        }}
                    >
                        Delete
                    </Button>
                </div>
            )
        }
    ];

    const fetchOrder = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/waybill/viewbulkorderdetails/${id}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setOrder(result);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };
    const orderSelectHandler = (e, order) => {
        setSelectedOrderId(order._id);
        setOrderNo(order.orderNo);
        const isChecked = e.target.checked;
        setCheckedOrders((prevOrders) =>
            isChecked ? [...prevOrders, order] : prevOrders.filter((checkedOrder) => checkedOrder.orderNo !== order.orderNo)
        );
    };

    const getBatchStatus = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/orderbatch/batchstatuses`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setBatchStatus(data.data);
                } else {
                    toast.error(data.message);
                }
            });
    };

    const getOrders = async (data) => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const urlParams = new URLSearchParams(window.location.search);
        let url = `${ApiUrl.BaseUrl}/waybill/ordersToday`;

        if (data.orderType) {
            urlParams.append('orderType', data.orderType);
        }

        if (data.startDate) urlParams.append('startDate', data.startDate);
        if (data.endDate) urlParams.append('endDate', data.endDate);

        url = `${url}?${urlParams.toString()}`;
        await fetch(url, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const orders = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    setOrders(JSON.parse(orders));
                } else {
                    toast.error(data.message);
                }
            });
    };

    const getDrivers = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/driver/availabledrivers`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const drivers = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    drivers.push({
                        _id: '3pl',
                        firstName: '3PL',
                        lastName: 'Driver'
                    });
                    setDriverList(JSON.parse(drivers));
                } else {
                    toast.error(data.message);
                }
            });
    };

    const getCars = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/garagevehicleslist`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const cars = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    setCarList(JSON.parse(cars));
                } else {
                    toast.error(data.message);
                }
            });
    };
    const fetchAllProducts = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/partzone/salespartspricelist`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = data.data;
                        setProducts(result);
                    } else {
                        toast.error(data.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        fetchOrder();
        getBatchStatus();
        getDrivers();
        getCars();
        fetchAllProducts();
    }, []);

    const handlerOrderTypeHandler = async (order) => {
        await getOrders({ orderType: order.orderType });
    };

    const batchStatusOptions = batchStatus.map((item) => {
        return {
            label: item.name,
            value: item._id
        };
    });
    const driverOptions = driverList.map((item) => {
        return {
            label: `${item.firstName} ${item.lastName}`,
            value: `${item.firstName} ${item.lastName}`
        };
    });
    const carOptions = carList.map((item) => {
        return {
            label: item.vehicleNumber,
            value: item.vehicleNumber
        };
    });
    const productsOptions = products.map((item) => {
        return {
            label: item.sku,
            value: item.sku
        };
    });

    const handleDateChange = (date, dateString) => {
        setBatchDate(date);
    };

    const generateBatchHandlder = async () => {
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const datas = {
            batchDate: new Date(batchDate).toISOString('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            batchStatusId: order.status,
            orderType: order.orderType,
            rmaName: `${order?.placedBy?.firstName} ${order?.placedBy?.lastName}`,
            orderId: order._id,
            orderNo: order.orderNo,
            orderItems: order.items
            // userId: localStorage.getItem('userId')
        };
        console.log(datas, 'datas');
        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/waybill/batchorder`, {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify({ payload })
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setBatchData(data.data);
                    toast.success(data.message);
                    setLoading(false);
                    setCarNo('');
                    setBatchDate('');
                    setBatchStatusId('');
                    setOrderType('');
                    setCheckedOrders([]);
                    // setModalVisible(true);
                    setTimeout(() => {
                        window.location.href = '/waybill/batch';
                    }, 2000);
                } else {
                    console.log(data, 'data');
                    const error = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    toast.error(error);
                    setLoading(false);
                }
            });
    };

    const handleGenerateWaybill = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const datas = {
                batchid: batchData._id,
                batchDate: batchData.batchDate,
                batchStatusId: batchData.batchStatusId,
                rmaName: batchData.rmaName,
                orderNo: batchData.orderNo,
                orderId: batchData.orderId,
                orderType: batchData.orderType,
                ordersDetails: batchData?.orderItems,
                driverName
            };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            await fetch(`${ApiUrl.BaseUrl}/waybill/generatewaybill`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        toast.success(data.message);
                        setLoading(false);
                        setModalVisible(false);
                        setOrderModalVisible(false);
                        setSelectedOrderId(null);
                        setTimeout(() => {
                            window.location.href = '/generated/waybill';
                        }, 2000);
                    } else {
                        const error = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                        toast.error(error);
                        setLoading(false);
                    }
                });
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        }
    };

    const updateOrderHandler = () => {
        const updatedOrderDetails = order?.items?.map((order) => {
            if (order._id === orderItemSelect._id) {
                return { ...order, partid: itemName, qty: quantity };
            }
            return order;
        });
        setOrder({ ...order, items: updatedOrderDetails });

        // Close the modal
        setOrderModalVisible(false);
    };
    const updateFieldValue = (id, field, value) => {
        setProductListSelect((prevFields) =>
            prevFields.map((prevField) => (prevField.id === id ? { ...prevField, [field]: value } : prevField))
        );
    };
    const addProduct = () => {
        const newProduct = {
            id: uuidv4(),
            key: uuidv4(),
            partid: '',
            qty: ''
        };

        setProductListSelect((prevFields) => [...prevFields, newProduct]);
    };
    const removeProduct = (id) => {
        if (id) {
            setProductListSelect((prevFields) => prevFields.filter((prevField) => prevField.id !== id));
        }
    };
    const addProductHandler = () => {
        productListSelect.forEach((item) => {
            if (item.partId && item.qty) {
                const existingItemIndex = order?.items?.findIndex(
                    (existingItem) => existingItem.partid.toLowerCase() === item.partId.toLowerCase()
                );

                if (existingItemIndex !== -1) {
                    // If the partId already exists in order.items, update the quantity
                    const updatedItems = [...order.items];
                    updatedItems[existingItemIndex].qty += parseInt(item.qty, 10);

                    setOrder((prevFields) => ({ ...prevFields, items: updatedItems }));
                    toast.success('Quantity updated for existing product in order');
                    setProductListSelect([{ id: uuidv4(), key: uuidv4(), partId: '', qty: '' }]);
                } else {
                    // If the partId doesn't exist, add a new product
                    const newProduct = {
                        _id: uuidv4(),
                        partid: item.partId,
                        qty: parseInt(item.qty, 10)
                    };

                    setOrder((prevFields) => ({ ...prevFields, items: [...prevFields.items, newProduct] }));
                    toast.success('Product added to order');
                    setProductListSelect([{ id: uuidv4(), key: uuidv4(), partId: '', qty: '' }]);
                }
            }
        });
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/waybill/orders" className="createScheduleLink">
                    <p style={{ fontSize: '25px', marginLeft: '10px' }}>
                        <ArrowBackIcon sx={{ fontSize: '25px', color: '#000' }} /> Batch Orders
                    </p>
                </Link>
                <Button
                    style={{ backgroundColor: '#FF740F', color: '#fff', width: '300px' }}
                    className="mt-2 mb-2 d-flex align-items-center justify-content-center btnCreateSchedule"
                    title="Apply Filters"
                    loading={loading}
                    onClick={generateBatchHandlder}
                >
                    Batch order
                </Button>
            </div>
            <div className="batchContainer">
                <div className="p-4">
                    <div className="addSalePlanInput">
                        <div className="mr-2">
                            <p style={{ fontSize: '16px', color: '#546974' }}>Batch Date</p>
                            <Input
                                size="large"
                                style={{
                                    width: '300px'
                                }}
                                placeholder="Batch Date"
                                onChange={(e) => setBatchDate(e.target.value)}
                                value={dayjs(batchData?.batchDate).format('YYYY-MM-DD')}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="addSalePlanInput">
                        <div className="mr-2">
                            <p style={{ fontSize: '16px', color: '#546974' }}>Order Type</p>
                            <Input
                                placeholder="Order Type"
                                type="text"
                                size="large"
                                style={{
                                    width: '300px'
                                }}
                                value={order.orderType}
                                onChange={(e) => setOrderType(e.target.value)}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="addSalePlanInput">
                        <div>
                            <p style={{ fontSize: '16px', color: '#546974' }}>Batch Status</p>
                            <Input
                                placeholder="Batch Status"
                                type="text"
                                size="large"
                                style={{
                                    width: '300px'
                                }}
                                value={order.status}
                                onChange={(e) => setBatchStatusId(e.target.value)}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="addSalePlanInput">
                        <div>
                            <p style={{ fontSize: '16px', color: '#546974' }}>Order No</p>
                            <Input
                                placeholder="Order No"
                                type="text"
                                size="large"
                                style={{
                                    width: '300px'
                                }}
                                value={order.orderNo}
                                onChange={(e) => setOrderNo(e.target.value ?? '')}
                                readOnly
                            />
                            {checkedOrders && checkedOrders.length > 0 && (
                                <div>
                                    <div className="mt-2">
                                        {checkedOrders.map((checkedOrder, index) => (
                                            <p key={index}>
                                                Order: {checkedOrder.orderNo} - {checkedOrder.rma}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <p style={{ fontSize: '16px', color: '#546974' }}>Products in this order ({order?.items?.length})</p>
                        <Button
                            onClick={() => setProductModalVisible(true)}
                            type="primary"
                            className="mb-2"
                            style={{ backgroundColor: 'transparent', color: '#4B6EFF', border: '1px solid #DBE2FF' }}
                        >
                            Add Product <PlusOutlined />
                        </Button>
                    </div>
                    <div className="productsContainer orderListContainer">
                        <Table rowKey={(record) => record._id} columns={columns} dataSource={order?.items} pagination={false} />
                    </div>
                </div>
            </div>
            <Modal title="Select Orders" open={ordersModal} footer={null} onCancel={() => setOrdersModal(false)}>
                <div className="productsContainer">
                    <RangePicker
                        size="large"
                        format="YYYY-MM-DD"
                        className="datePicker filterInput"
                        onChange={(date) => {
                            getOrders({
                                startDate: date[0],
                                endDate: date[1],
                                orderType
                            });
                        }}
                    />
                    {orders?.map((order) => (
                        <div key={order._id}>
                            <Divider />
                            <Radio
                                key={order._id}
                                onChange={(e) => orderSelectHandler(e, order)}
                                name={`orderRadio_${order._id}`}
                                checked={selectedOrderId === order._id}
                            >
                                Order: {order.orderNo} - {order.rma}
                            </Radio>
                        </div>
                    ))}
                </div>
            </Modal>
            <Modal
                footer={null}
                open={orderModalVisible}
                onCancel={() => {
                    setOrderModalVisible(false);
                }}
                width={400}
            >
                <div>
                    <p className="text-center mb-4 mt-2" style={{ fontSize: '14px' }}>
                        Edit item
                    </p>
                    <div>
                        <p style={{ fontSize: '12px' }}>Item name</p>
                        <Input
                            placeholder="Item name"
                            size="large"
                            value={itemName}
                            onChange={(e) => setItemName(e.target.value)}
                            style={{ width: '100%' }}
                            readOnly
                        />
                    </div>
                    <div className="mt-2">
                        <p style={{ fontSize: '12px' }}>
                            Quantity <span style={{ color: 'red' }}>*</span>
                        </p>
                        <Input
                            placeholder="Quantity"
                            size="large"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                        <Button
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB'
                            }}
                            className="mr-2"
                            onClick={() => {
                                setOrderModalVisible(false);
                            }}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB'
                            }}
                            onClick={updateOrderHandler}
                            loading={loading}
                            disabled={loading}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                title="Add Product"
                footer={null}
                open={productModalVisible}
                onCancel={() => {
                    setProductModalVisible(false);
                }}
                width={700}
            >
                <div>
                    {productListSelect.map((product, id) => (
                        <div className="product mb-4" key={id}>
                            <div>
                                <p>
                                    Product <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                                </p>
                                <Select
                                    options={productsOptions}
                                    value={product.partId}
                                    onChange={(value) => updateFieldValue(product.id, 'partId', value)}
                                    placeholder="Select Product"
                                    allowClear
                                    showSearch
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    size="large"
                                    className="input"
                                />
                            </div>
                            <div>
                                <p>
                                    Quantity <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                                </p>
                                <Input
                                    placeholder="Quantity"
                                    size="large"
                                    value={product.qty}
                                    onChange={(e) => updateFieldValue(product.id, 'qty', e.target.value)}
                                    className="input"
                                />
                            </div>
                            <div className="productActions">
                                {id >= 1 && (
                                    <Button
                                        style={{ color: '#FF740F', borderColor: '#E7EAEB' }}
                                        onClick={() => removeProduct(product.id)}
                                        className="mr-2"
                                    >
                                        <MinusOutlined />
                                    </Button>
                                )}
                                <Button onClick={() => addProduct()} className="mr-2" style={{ color: '#FF740F', borderColor: '#E7EAEB' }}>
                                    <PlusOutlined />
                                </Button>
                            </div>
                        </div>
                    ))}
                    <div className="d-flex">
                        <Button
                            type="primary"
                            onClick={() => {
                                setProductModalVisible(false);
                            }}
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB',
                                marginRight: '10px',
                                width: '300px',
                                backgroundColor: 'transparent'
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB',
                                marginRight: '10px',
                                width: '300px',
                                backgroundColor: 'transparent'
                            }}
                            onClick={addProductHandler}
                        >
                            Add Product
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal footer={null} open={modalVisible} width={400}>
                <div className="text-center">
                    <h5>Batching successful</h5>
                    <p className="mb-4 mt-2" style={{ fontSize: '14px' }}>
                        You have successfully batched this order, <br /> proceed to generate a waybill for this order
                    </p>
                    <div className="d-flex align-items-center">
                        <Button
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB',
                                width: '100%'
                            }}
                            disabled={loading}
                            onClick={handleGenerateWaybill}
                        >
                            Generate waybill
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Index;
