import { Button, DatePicker, Input, Select } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { decrypt, encrypt } from '../../../utils/encrypt';
import ApiUrl from '../../../network';
import { toast } from 'react-toastify';

export const Add = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [customer, setCustomer] = useState('');
    const [amtDue, setAmtDue] = useState('');
    const [description, setDescription] = useState('');
    const [rma, setRma] = useState('');
    const [credittrackerid, setCreditTrackerId] = useState('');
    const [reconAmt, setReconAmt] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [rmaCc, setRmaCc] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [creditTrackerData, setCreditTrackerData] = useState([]);
    const [credits, setCredits] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dueAmountPlaceholder, setDueAmountPlaceholder] = useState('');

    const fetchRmaCc = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            await fetch(`${ApiUrl.BaseUrl}/epl/eplcustomersrmacc`, {
                method: 'GET',
                headers: config.headers
            })
                .then((data) => data.json())
                .then((rmaccData) => {
                    if (rmaccData.message === 'Success') {
                        const decryptedRmaccData = JSON.parse(decrypt(rmaccData.data, keyResult, ivResult));
                        setRmaCc(decryptedRmaccData.rmcc);
                        setCustomerData(decryptedRmaccData.customer);
                    } else {
                        toast.error(rmaccData.data);
                    }
                });
        } catch (error) {
            toast.error('Error occurred while fetching RMA');
        }
    };

    const fetchAllData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const creditUrl = `${ApiUrl.BaseUrl}/epl/credittrackerdata`;
            const creditResponse = await fetch(creditUrl, config);
            const creditData = await creditResponse.json();
            if (creditData.message === 'success') {
                const decryptedCreditData = JSON.parse(decrypt(creditData.data, keyResult, ivResult));
                setCreditTrackerData(decryptedCreditData);
            } else {
                toast.error(creditData.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const fetchAllCreditData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const creditUrl = `${ApiUrl.BaseUrl}/epl/creditnos`;
            const creditResponse = await fetch(creditUrl, config);
            const creditData = await creditResponse.json();
            if (creditData.message === 'success') {
                const decryptedCreditData = JSON.parse(decrypt(creditData.data, keyResult, ivResult));
                setCredits(decryptedCreditData);
            } else {
                toast.error(creditData.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        fetchRmaCc();
        fetchAllCreditData();
        fetchAllData();
    }, []);

    const rmaOptions = rmaCc.map((rmacc) => {
        return {
            value: rmacc._id,
            label: `${rmacc.firstName} ${rmacc.lastName}`
        };
    });

    const creditOptions = credits.map((credit) => {
        return {
            value: credit._id,
            label: `${credit.creditno} - ${credit?.customer?.partnerName}`
        };
    });

    useEffect(() => {
        const fetchCustomerCreditData = async () => {
            try {
                const userInfo = localStorage.getItem('userToken');
                if (!userInfo) {
                    toast.error('User not authenticated');
                    return;
                }
                const config = {
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    }
                };

                if (credittrackerid) {
                    const creditUrl = `${ApiUrl.BaseUrl}/epl/creditnodetails/${credittrackerid}`;
                    const creditResponse = await fetch(creditUrl, config);
                    const creditData = await creditResponse.json();
                    if (creditData.message.toLowerCase() === 'success') {
                        const decryptedCreditData = JSON.parse(decrypt(creditData.data, keyResult, ivResult));
                        setCustomer(decryptedCreditData?.customer?.partnerName);
                        setAmtDue(decryptedCreditData?.amtDue || '');
                        setDueAmountPlaceholder(decryptedCreditData?.amtDue);
                        setDescription(decryptedCreditData?.description || '');
                        setRma(
                            `${decryptedCreditData?.rmacc?.firstName.trim() || ''} ${decryptedCreditData?.rmacc?.lastName.trim() || ''}`
                        );
                    } else {
                        toast.error(creditData.message);
                    }
                }
            } catch (error) {
                toast.error(error.message);
            }
        };

        fetchCustomerCreditData();
    }, [credittrackerid]);

    const createPaymentHandler = async () => {
        try {
            if (!customer || !amtDue || !description || !rma || !credittrackerid) {
                toast.error('All fields are required');
                return;
            }
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const rmaID = rmaCc.find((rmacc) => `${rmacc.firstName.trim()} ${rmacc.lastName.trim()}` === rma);
            const customerID = customerData.find((customerFound) => customerFound.partnerName.trim() === customer.trim());

            const datas = {
                customer: customerID?._id,
                amtPaid: amtDue,
                recon_amt: reconAmt,
                payment_date: paymentDate,
                description,
                rmcc: rmaID._id || rma,
                credittrackerid
            };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            await fetch(`${ApiUrl.BaseUrl}/epl/addeplcreditpayment`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setLoading(false);
                        toast.success(data.message);
                        setCustomer('');
                        setAmtDue('');
                        setDescription('');
                        setRma('');
                        setCreditTrackerId('');
                        setReconAmt('');
                        setPaymentDate('');
                    } else {
                        setLoading(false);
                        toast.error(data.message);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    };

    const handleChange = (e) => {
        // Update the customer state
        setCreditTrackerId(e);
    };

    return (
        <div className="easy-pay-container">
            <div className="header">
                <Link className="d-flex align-items-center" to="/epl/easy-pay-later" style={{ color: '#0A2938' }}>
                    <ArrowLeftOutlined className="font-20" />
                    <h1 className="font-30 ml-2 mt-2">Add Payment </h1>
                </Link>
                <Button
                    loading={loading}
                    onClick={() => {
                        createPaymentHandler();
                    }}
                    style={{ backgroundColor: '#FF740F', color: '#fff', width: 'fit-content' }}
                >
                    Add Payment
                </Button>
            </div>
            <hr className="divider" />
            <div className="content">
                <div className="bg-white add-inputs-container">
                    <div className="form-group">
                        <span>
                            Credit <span className="text-danger">*</span>
                        </span>
                        <Select
                            value={credittrackerid}
                            onChange={handleChange}
                            size="middle"
                            className="mt-2"
                            showSearch
                            allowClear
                            options={creditOptions}
                            defaultValue="Select option"
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <span>Customer</span>
                        <Input
                            value={customer}
                            onChange={(e) => setCustomer(e.target.value)}
                            size="middle"
                            className="mt-2"
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="form-group">
                        <span>
                            Amount To Pay {amtDue && <small className="text-danger"> ( Amount Due {dueAmountPlaceholder} ) </small>}
                        </span>
                        <Input
                            value={amtDue}
                            onChange={(e) => setAmtDue(e.target.value)}
                            type="text"
                            className="form-control mt-2"
                            id="amount"
                        />
                    </div>
                    <div className="form-group">
                        <span>Description</span>
                        <Input
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            type="text"
                            className="form-control mt-2"
                            id="description"
                        />
                    </div>
                    <div className="form-group">
                        <span>RMA/CC Direct contact</span>
                        <Select
                            value={rma}
                            onChange={(e) => setRma(e)}
                            size="middle"
                            className="mt-2"
                            showSearch
                            allowClear
                            options={rmaOptions}
                            defaultValue="Select option"
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <span>Reconciled Amount</span>
                        <Input
                            value={reconAmt}
                            onChange={(e) => setReconAmt(e.target.value)}
                            type="text"
                            className="form-control mt-2"
                            id="reconAmt"
                        />
                    </div>
                    <div className="form-group">
                        <span>Payment Date</span>
                        <DatePicker
                            value={paymentDate}
                            onChange={(date) => setPaymentDate(date)}
                            className="form-control mt-2"
                            id="paymentDate"
                        />
                    </div>
                    <div className="form-group" />
                </div>
            </div>
        </div>
    );
};

export default Add;
