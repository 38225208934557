import React, { useState, useEffect } from 'react';
import { Table, Divider, Paper, Space, Select, Spin, DatePicker, Modal, Input, Dropdown, Popover, Button } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined, DownloadOutlined, FileAddOutlined } from '@ant-design/icons';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import { useAppContext } from '../../context/appContext';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const SalesDriveOrderTable = ({
    loading,
    salesDriveOrder,
    rmaOptions,
    cityOptions,
    paymentStatusOptions,
    countryOptions,
    customerOptions,
    orderStatusOptions,
    filterCity,
    filterCountry,
    filterCustomer,
    filterDate,
    filterOrderNo,
    filterOrderStatus,
    filterPaymentStatus,
    filterRmaPerson,
    selectedDate,
    handlePdfClick,
    handleReceiptClick,
    handleLimit,
    limitOptions,
    limit,
    handleEditClick,
    handlePage,
    tableParams
}) => {
    const [open, setOpen] = useState(true);
    const [orderModal, setOrderModal] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);

    const dateConverter = (value) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };

        return new Date(value).toLocaleDateString('en-US', options);
    };

    // 2 decimal places
    const twoDecimalPlaces = (value) => {
        return parseFloat(value?.toFixed(2)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    const userRole = localStorage.getItem('userRole');

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const toggleOpenClose = () => {
        setOpen(!open);
    };

    const { RangePicker } = DatePicker;

    const navigate = useNavigate();

    const columns = [
        {
            title: '#',
            dataIndex: '#',
            key: 'id',

            render: (id, record, index) => {
                return index + 1;
            },
            width: '30px'
        },
        {
            title: 'Order No',
            dataIndex: 'orderNo',
            key: 'orderNo',
            width: '100px'
        },
        {
            title: 'Order Date',
            dataIndex: 'orderDate',
            key: 'orderDate',
            width: '180px'
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
            width: '150px'
        },

        {
            title: 'Sales Person',
            dataIndex: 'salesPerson',
            key: 'salesPerson',
            width: '150px'
        },
        {
            title: 'Placed by',
            dataIndex: 'placedby',
            key: 'placedby',
            width: '150px'
        },
        {
            title: ' Cost Value',
            dataIndex: 'costValue',
            key: 'costValue',
            width: '100px'
        },
        {
            title: 'Sales Value',
            dataIndex: 'salesvalue',
            key: 'salesvalue',
            width: '100px'
        },
        {
            title: 'Original Sales Value',
            dataIndex: 'originalsalesvalue',
            key: 'originalsalesvalue',
            width: '110px'
        },
        {
            title: 'Discount volume',
            dataIndex: 'discountVolume',
            key: 'discountVolume',
            width: '130px'
        },
        {
            title: 'Order Status',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            width: '100px'
        },

        {
            title: 'Receipt Status',
            dataIndex: 'receiptStatus',
            key: 'receiptStatus',
            width: '100px'
        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: '100px'
        }
    ];

    const oderDetails = salesDriveOrder?.map((result) => ({
        key: result?._id,
        orderType: result?.orderType === 'Bulk' ? 'Regular' : result?.orderType,
        orderNo: result?.fulOrderId,
        orderDate: dateConverter(result?.orderDate),
        customerName: result?.customerName?.partnerName || result.customerNameId,
        salesPerson: result?.salesTeamId,
        placedby: result?.placeBy,
        receiptStatus: result?.receiptStatus?.name,
        costValue: twoDecimalPlaces(result?.costValue),
        salesvalue: twoDecimalPlaces(result?.salesValue),
        orderStatus: result?.orderStatus,
        originalsalesvalue: twoDecimalPlaces(result?.originalSalesValue),
        discountVolume: twoDecimalPlaces(result?.discountVolume),
        action: (
            <>
                <Popover
                    placement="rightTop"
                    content={
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                padding: '5px 0px',
                                minWidth: '100px'
                            }}
                        >
                            <IconButton
                                aria-label="delete"
                                color="success"
                                sx={{
                                    fontSize: '18px',
                                    padding: '5px',
                                    borderRadius: '0px ',
                                    fontWeight: '600'
                                    // width: '100%'
                                }}
                                onClick={() => handleReceiptClick(result)}
                            >
                                receipt
                            </IconButton>

                            <IconButton
                                onClick={() => (window.location.href = `/addissue/${result?._id}`)}
                                sx={{
                                    borderBottom: '1px solid #ECEDEF',
                                    height: '40px',
                                    width: '100%',
                                    padding: '10px 12px',
                                    color: '#ff740f',
                                    fontWight: '500',
                                    textAlign: 'center',
                                    borderRadius: '8px 8px 0 0',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    margin: '10px 0'
                                }}
                                className="rma-action"
                            >
                                Issue <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                            </IconButton>

                            <IconButton
                                aria-label="view"
                                color="warning"
                                sx={{
                                    fontSize: '18px',
                                    padding: '5px ',
                                    borderRadius: '0px ',
                                    fontWeight: '600'
                                }}
                                onClick={() => handlePdfClick(result)}
                            >
                                pdf
                            </IconButton>
                            {/* {result?.receiptStatus?._id === '622b9bcdf3bcbc9eb0c203e2' ||
                            result?.receiptStatus?._id === '622b9bf0f3bcbc9eb0c203ea' ||
                            result?.receiptStatus?._id === '6228632de5b218f4be037d70' ||
                            result?.receiptStatus?._id === '622b9be0f3bcbc9eb0c203e6' ? (
                                ''
                            ) : (
                                <>
                                    {result?.paymentStatus?._id === '620d76a4ee512a471bf98c3e' ||
                                    result?.paymentStatus?._id === '6542467c04e82ed78cd79ec6' ? (
                                        ''
                                    ) : (
                                        <>
                                            {userRole === 'CustomerSuccess' ? (
                                                <IconButton
                                                    sx={{
                                                        fontSize: '18px',
                                                        padding: '5px ',
                                                        borderRadius: '0px ',
                                                        fontWeight: '600'
                                                    }}
                                                    aria-label="edit"
                                                    color="secondary"
                                                    onClick={() => handleEditClick(result)}
                                                >
                                                    Update
                                                </IconButton>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    )}
                                </>
                            )} */}
                            <IconButton
                                aria-label="delete"
                                color="success"
                                sx={{
                                    fontSize: '18px',
                                    padding: '5px',
                                    borderRadius: '0px ',
                                    fontWeight: '600'
                                    // width: '100%'
                                }}
                                onClick={() => (window.location.href = `/epl/cc-rma-request-add/${result?._id}`)}
                            >
                                Epl Request
                            </IconButton>
                            <IconButton
                                sx={{
                                    borderBottom: '1px solid #ECEDEF',
                                    height: '40px',
                                    width: '100%',
                                    padding: '10px 12px',
                                    color: '#ff740f',
                                    fontWight: '500',
                                    textAlign: 'center',
                                    borderRadius: '8px 8px 0 0',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    margin: '10px 0'
                                }}
                                onClick={() => (window.location.href = `/payment-initiation/${result?._id}`)}
                            >
                                Payment Initiation <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    setOrderDetails(result.imageUploads);
                                    setOrderModal(true);
                                }}
                                sx={{
                                    borderBottom: '1px solid #ECEDEF',
                                    height: '40px',
                                    width: '100%',
                                    padding: '10px 12px',
                                    color: '#ff740f',
                                    fontWight: '500',
                                    textAlign: 'center',
                                    borderRadius: '8px 8px 0 0',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    margin: '10px 0'
                                }}
                                className="rma-action"
                            >
                                Order Image <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                            </IconButton>
                        </Box>
                    }
                    trigger="click"
                >
                    <MoreHorizIcon
                        sx={{
                            display: 'flex',
                            cursor: 'pointer',
                            width: '100%'
                        }}
                    />
                </Popover>
            </>
        )
    }));

    // download order list
    const { handleExcelDataExport } = useAppContext();
    const downloadExcel = () => {
        const orderFile = oderDetails.map((obj) => {
            return Object.fromEntries(Object.entries(obj).filter(([key]) => key !== 'key' && key !== 'value' && key !== 'action'));
        });

        if (orderFile?.length === 0) return;

        handleExcelDataExport(orderFile, 'salesDriveOrder');
    };
    return (
        <div>
            <>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '32px 0 8px',
                        cursor: 'pointer'
                    }}
                    onClick={toggleOpenClose}
                >
                    {' '}
                    <Typography component="span" variant="h4" sx={{ fontWeight: 500, fontSize: '18px', color: '#434A5B' }}>
                        {open ? 'Hide filters' : 'Show filters'}
                    </Typography>
                    {open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                </Box>
                <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                        margin: '0 0 16px 0'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        {userRole === 'Admin' ? (
                            <Box
                                sx={{
                                    width: ' 239px',
                                    margin: '24px 0 0 0px'
                                }}
                            >
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search by Country</Typography>

                                <Select
                                    style={{
                                        width: '100%'
                                    }}
                                    size="large"
                                    placeholder="Country"
                                    options={countryOptions}
                                    onChange={(value) => filterCountry(value)}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                />
                            </Box>
                        ) : null}
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search by City</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="City"
                                options={cityOptions}
                                onChange={(value) => filterCity(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search by Order Status</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Order Status"
                                options={orderStatusOptions}
                                onChange={(value) => filterOrderStatus(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search by Payment Status</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Payment Status"
                                options={paymentStatusOptions}
                                onChange={(value) => filterPaymentStatus(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search by Date</Typography>
                            <RangePicker
                                format="YYYY-MM-DD"
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                value={selectedDate}
                                onChange={filterDate}
                            />
                        </Box>

                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search Partner Name</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Partner Name"
                                options={customerOptions}
                                onChange={(value) => filterCustomer(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>

                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search OrderNo#</Typography>

                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Search OrderNo#"
                                onChange={(e) => filterOrderNo(e.target.value.toLocaleUpperCase())}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Filter By RMA</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Filter By RMA"
                                options={rmaOptions}
                                onChange={(value) => filterRmaPerson(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Limit</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Select limit"
                                options={limitOptions}
                                onChange={(value) => handleLimit(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                    </Box>
                </Collapse>
                <Box
                    sx={{
                        width: '100%',
                        margin: '20px 0',
                        padding: '5px 0px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap'
                    }}
                >
                    <Button
                        type="primary"
                        size="large"
                        style={{
                            backgroundColor: 'transparent',
                            borderRadius: '8px',
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#FF740F',
                            border: '1px solid #FF740F',
                            margin: '24px 24px 0 0px',
                            height: '48px',
                            width: '174px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        onClick={() => {
                            downloadExcel();
                        }}
                    >
                        Download Excel
                    </Button>
                </Box>
            </>
            <Table
                loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                columns={columns}
                dataSource={oderDetails}
                size="middle"
                scroll={{
                    x: 'calc(700px + 50%)',
                    y: 580
                }}
                pagination={tableParams.pagination}
                onChange={handlePage}
            />
            <Modal
                title="Order Image"
                open={orderModal}
                onCancel={() => {
                    setOrderModal(false);
                }}
                width={400}
                footer={null}
            >
                <div>
                    {orderDetails.map((item) => (
                        <div
                            key={item._id}
                            style={{
                                marginBottom: '20px',
                                padding: '10px',
                                border: '1px solid #ECEDEF',
                                borderRadius: '8px'
                            }}
                        >
                            <div className="mb-4" style={{ border: '1px solid #ECEDEF', padding: '10px', borderRadius: '8px' }}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: '#546974',
                                        fontSize: '14px'
                                    }}
                                >
                                    <span style={{ color: '9B979E' }}> Captured By: </span>
                                    <span style={{ fontSize: '16px', color: '#FF740F' }}>{item?.capturedUser}</span>
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        borderRadius: '5px',
                                        fontSize: '14px'
                                    }}
                                >
                                    Captured Date:{' '}
                                    {new Date(item?.capturedDate).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                </Typography>
                            </div>
                            <img
                                src={item?.imgFile}
                                alt="Order"
                                style={{
                                    width: '100%',
                                    borderRadius: '8px',
                                    height: '200px',
                                    objectFit: 'cover',
                                    border: '1px solid #ECEDEF'
                                }}
                            />
                        </div>
                    ))}
                    {orderDetails.length === 0 && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '200px',
                                border: '1px solid #ECEDEF',
                                borderRadius: '8px'
                            }}
                        >
                            <Typography
                                variant="h6"
                                style={{
                                    color: '#546974',
                                    fontSize: '14px'
                                }}
                            >
                                No Order Image found
                            </Typography>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default SalesDriveOrderTable;
