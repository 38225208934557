import { Table, Button, Input, Select, Collapse, Modal, Upload, message } from 'antd';
import { useState, useEffect } from 'react';
import { ReloadOutlined, CheckCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { decrypt, encrypt } from '../../utils/encrypt';
import { toast } from 'react-toastify';
import ApiUrl from '../../network';
import normFile from '../../imageToBucket';

const { Dragger } = Upload;

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

const Index = () => {
    const [filters, setFilters] = useState({
        search: '',
        routes: '',
        pods: ''
    });
    const [retailers, setRetailers] = useState([]);
    const [pods, setPods] = useState([]);
    const [retailerId, setRetailerId] = useState('');
    const [retailerDetails, setRetailerDetails] = useState({
        firstName: '',
        lastName: '',
        dataCountry: localStorage.getItem('country'),
        shopData: {
            shopname: '',
            shoplocation: '',
            shoplat: '',
            shoplng: '',
            street: '',
            neighbourhood: '',
            city: '',
            state: '',
            zip: '',
            addressDescription: ''
        },
        stage: '1',
        shopPhoto: '',
        nationalId: '',
        notes: ''
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalCount, setTotalCount] = useState(0);

    // fetch retailers
    const fetchRetailers = async (page = currentPage, size = pageSize) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const params = new URLSearchParams({
                page,
                limit: size
            });

            Object.entries(filters).forEach(([key, value]) => {
                if (value !== '') {
                    params.append(key, value);
                }
            });

            const response = await fetch(
                `${ApiUrl.BaseUrl}/crm/get-channel-partners/verified?country=${localStorage.getItem('country')}&${params.toString()}`,
                config
            );
            const data = await response.json();

            if (data.status === true || data.message === '') {
                const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));

                setRetailers(decryptedData.data);
                setTotalCount(decryptedData.totalCount);
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(data.message);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchPodsData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesdrivepod/salesdrivepod`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));

                        setPods(result);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    // update retailer
    const updateRetailer = async () => {
        try {
            setLoadingUpdate(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/crm/update-channel-partner/${retailerId}`, {
                method: 'PATCH',
                body: JSON.stringify(retailerDetails),
                headers: config.headers
            });
            const data = await response.json();

            if (data.status === true || data.message === '') {
                const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                toast.success(decryptedData);
                setModalOpen(false);
            } else {
                toast.error(data.message);
            }
            setLoadingUpdate(false);
        } catch (error) {
            toast.error('Failed to update retailer');
            setLoadingUpdate(false);
        }
    };

    useEffect(() => {
        fetchRetailers();
        fetchPodsData();
    }, [filters, pageSize, currentPage]);

    const retailerData = retailers.map((retailer) => ({
        key: retailer?.retailerId,
        name: `${retailer?.firstName} ${retailer?.lastName}`,
        contact: retailer?.partnerContact ? retailer?.partnerContact : '-',
        pod: retailer?.podDetails?.name ? retailer?.podDetails?.name : '-',
        route: retailer?.route ? retailer?.route : '-'
    }));

    const styles = {
        header: {
            backgroundColor: '#0A2938',
            padding: 20,
            borderRadius: 10
        },
        headerContent: {
            fontFamily: 'Poppins',
            fontSize: 30,
            fontWeight: 500,
            lineHeight: '38px',
            textAlign: 'left',
            color: '#0A2938'
        },
        contentHeaderParagraph: {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'left',
            color: '#0A2938'
        },
        content: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#696E7C'
        },
        filters: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#696E7C'
        },
        filterButton: {
            width: '140px',
            borderRadius: '8px',
            backgroundColor: '#FF740F',
            color: '#fff',
            border: 'none',
            marginRight: '10px'
        },
        resetButton: {
            width: 'fit-content',
            borderRadius: '8px',
            backgroundColor: '#fff',
            color: '#000',
            border: '1px solid #D9DBDE'
        },
        table: {
            borderRadius: '16px',
            backgroundColor: '#fff',
            border: '1px solid #E5E5E5'
        },
        actionButton: {
            backgroundColor: 'transparent',
            color: '#FF740F',
            border: '1px solid #FF740F',
            borderRadius: '8px',
            fontFamily: 'Poppins',
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 500,
            width: 'fit-content'
        },
        modalHeader: {
            fontFamily: 'Poppins',
            fontSize: 24,
            fontWeight: 500,
            lineHeight: '32px',
            textAlign: 'left',
            color: '#E7EAEB'
        },
        modalParagraph: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#E7EAEB'
        },
        saveButton: {
            width: 'fit-content',
            borderRadius: '8px',
            backgroundColor: '#FF740F',
            color: '#fff',
            border: 'none'
        },
        modalHeaderContainer: {
            backgroundColor: '#233E4C',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#E7EAEB',
            padding: 20,
            borderRadius: 10
        }
    };

    /**
     * Mobile view styles for filters
     * To be applied when screen width is below a certain breakpoint (e.g., 768px)
     */
    const mobileStyles = {
        filters: {
            ...styles.filters,
            flexDirection: 'column',
            alignItems: 'stretch'
        },
        filterButton: {
            ...styles.filterButton,
            width: '100%',
            marginTop: '10px'
        },
        resetButton: {
            ...styles.resetButton,
            width: '100%',
            marginTop: '10px'
        }
    };

    const columns = [
        {
            title: 'Retailer',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Contact',
            dataIndex: 'contact',
            key: 'contact'
        },
        {
            title: 'POD',
            dataIndex: 'pod',
            key: 'pod'
        },
        {
            title: 'Route',
            dataIndex: 'route',
            key: 'route'
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Button onClick={() => (window.location.href = `/verification/second-stage/${record.key}`)} style={styles.actionButton}>
                        Verify Retailer
                    </Button>
                </div>
            )
        }
    ];

    const applyFilters = () => {
        // Apply the filters to the retailers data
        const filteredRetailers = retailers.filter((retailer) => {
            const nameMatch = retailer.partnerName.toLowerCase().includes(filters.search.toLowerCase());
            const routeMatch = !filters.routes || retailer.route === filters.routes;
            const podMatch = !filters.pods || retailer.pod.name === filters.pods;
            return nameMatch && routeMatch && podMatch;
        });

        // Update the retailers state with the filtered data
        setRetailers(filteredRetailers);

        // Reset pagination to the first page
        setCurrentPage(1);

        // Fetch the filtered data from the API
        fetchRetailers();
    };

    const resetFilters = () => {
        setFilters({
            search: '',
            routes: '',
            pods: ''
        });
        setCurrentPage(1);
        setPageSize(25);
        fetchRetailers();
    };

    const idUploadRequest = async (file, type) => {
        try {
            const token = localStorage.getItem('userToken');
            const rootURL = ApiUrl.BaseUrl;
            const compressedImage = await normFile(file, token, rootURL);

            if (compressedImage) {
                if (type === 'photo') {
                    setRetailerDetails({ ...retailerDetails, shopPhoto: compressedImage });
                } else {
                    setRetailerDetails({ ...retailerDetails, nationalId: compressedImage });
                }
                toast.success(type === 'photo' ? 'Photo uploaded successfully' : 'National ID uploaded successfully');

                return {
                    status: 'done',
                    response: compressedImage
                };
            }
            // Return a default value if compressedImage is falsy
            return {
                status: 'error',
                response: 'Failed to compress image'
            };
        } catch (error) {
            return {
                status: 'error'
            };
        }
    };

    const props = {
        name: 'file',
        multiple: true,
        customRequest: ({ file, onSuccess, onError }) => {
            idUploadRequest(file, 'photo')
                .then((response) => {
                    if (response.status === 'done') {
                        onSuccess(response);
                    } else {
                        onError(response);
                    }
                })
                .catch((error) => {
                    onError(error);
                });
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        }
    };

    const idProps = {
        name: 'file',
        multiple: false,
        customRequest: ({ file, onSuccess, onError }) => {
            idUploadRequest(file, 'id')
                .then((response) => {
                    if (response.status === 'done') {
                        onSuccess(response);
                    } else {
                        onError(response);
                    }
                })
                .catch((error) => {
                    onError(error);
                });
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        }
    };

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
        fetchRetailers(pagination.current, pagination.pageSize);
    };

    return (
        <div className="verification">
            <div className="header">
                <div className="header-content">
                    <h1 style={styles.headerContent}>Verification (STAGE 2)</h1>
                    <p style={styles.contentHeaderParagraph}>view all stage 1 verified retailer information</p>
                </div>
            </div>
            <div className="content">
                <div className="content-header">
                    <Collapse accordion>
                        <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                            <div className="filters" style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
                                <Input
                                    placeholder="Search by name"
                                    style={{ width: '200px' }}
                                    onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                    value={filters.search}
                                />
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="label"
                                    options={[
                                        { label: 'Route 1', value: 'route1' },
                                        { label: 'Route 2', value: 'route2' },
                                        { label: 'Route 3', value: 'route3' }
                                    ]}
                                    filterOption={(inputValue, option) => {
                                        // This function is used to filter options in a Select component
                                        // It compares the input value with the label of each option

                                        // Convert both the input and the option label to uppercase for case-insensitive comparison
                                        const upperInput = inputValue.toUpperCase();

                                        // Check if the option label is a string
                                        if (typeof option.label === 'string') {
                                            // If it's a string, directly check if the uppercase input is included in the uppercase label
                                            return option.label.toUpperCase().indexOf(upperInput) !== -1;
                                        }

                                        // If the label is not a string (e.g., it might be a number or other type)
                                        // Convert it to a string before comparing
                                        return String(option.label).toUpperCase().indexOf(upperInput) !== -1;
                                    }}
                                    placeholder="Select route"
                                    style={{ width: '200px' }}
                                    onChange={(value) => setFilters({ ...filters, routes: value })}
                                    value={filters.routes || null}
                                />

                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="label"
                                    options={pods.map((pod) => ({
                                        label: pod.name,
                                        value: pod._id
                                    }))}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    placeholder="Select POD"
                                    style={{ width: '200px' }}
                                    onChange={(value) => setFilters({ ...filters, pods: value })}
                                    value={filters.pods || null}
                                />

                                <div className="action_button">
                                    <Button style={styles.filterButton} onClick={applyFilters}>
                                        Apply filter
                                    </Button>
                                    <Button style={styles.resetButton} onClick={resetFilters}>
                                        <ReloadOutlined />
                                    </Button>
                                </div>
                            </div>
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <div className="content-body">
                    <div className="table-responsive" style={styles.table}>
                        <Table
                            rowKey={(record) => record.key}
                            columns={columns}
                            dataSource={retailerData}
                            loading={loading}
                            pagination={{
                                current: currentPage,
                                pageSize,
                                total: totalCount
                            }}
                            onChange={handleTableChange}
                            scroll={{ x: 1000 }}
                        />
                    </div>
                </div>
            </div>
            <Modal open={modalOpen} footer={null} onCancel={() => setModalOpen(false)} centered width={700}>
                <div>
                    <div className="modal-header" style={styles.modalHeaderContainer}>
                        <div>
                            <h1 style={styles.modalHeader}>Verification Criteria (STAGE 1)</h1>
                            <p style={styles.modalParagraph}>Click the circle to verify and the text to edit</p>
                        </div>
                        <div>
                            <Button
                                loading={loadingUpdate}
                                disabled={
                                    !retailerDetails.firstName ||
                                    !retailerDetails.lastName ||
                                    !retailerDetails.shopData.shopname ||
                                    !retailerDetails.shopData.shoplocation ||
                                    !retailerDetails.shopData.shoplat ||
                                    !retailerDetails.shopData.shoplng ||
                                    !retailerDetails.shopData.street ||
                                    !retailerDetails.shopData.neighbourhood ||
                                    !retailerDetails.shopData.city ||
                                    !retailerDetails.shopData.addressDescription
                                }
                                onClick={updateRetailer}
                                style={styles.saveButton}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <h3
                            style={{
                                marginBottom: '16px',
                                fontSize: '20px',
                                lineHeight: '30px',
                                fontWeight: 400,
                                fontFamily: 'Poppins'
                            }}
                        >
                            Verify retailer information
                        </h3>
                        <div
                            className="modal-body-content"
                            style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}
                        >
                            <span style={{ width: '100%', marginRight: '16px' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.firstName ? '#00AA55' : '#696E7C',
                                            backgroundColor: retailerDetails?.firstName ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    First Name
                                </p>
                                <Input
                                    value={retailerDetails.firstName}
                                    onChange={(e) => setRetailerDetails({ ...retailerDetails, firstName: e.target.value })}
                                    placeholder="Retailer First Name"
                                    style={{ width: '100%' }}
                                />
                            </span>
                            <span style={{ width: '100%' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.lastName ? '#00AA55' : '#696E7C',
                                            backgroundColor: retailerDetails?.lastName ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    Last Name
                                </p>
                                <Input
                                    value={retailerDetails?.lastName}
                                    onChange={(e) =>
                                        setRetailerDetails({
                                            ...retailerDetails,
                                            lastName: e.target.value
                                        })
                                    }
                                    placeholder="Retailer Last Name"
                                    style={{ width: '100%' }}
                                />
                            </span>
                        </div>
                        <h3
                            style={{
                                marginBottom: '16px',
                                fontSize: '20px',
                                lineHeight: '30px',
                                fontWeight: 400,
                                fontFamily: 'Poppins'
                            }}
                        >
                            Verify Location
                        </h3>
                        <div
                            className="modal-body-content"
                            style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}
                        >
                            <span style={{ width: '100%', marginRight: '16px' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.shopData?.shopname ? '#00AA55' : '#696E7C',
                                            backgroundColor: retailerDetails?.shopData?.shopname ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    Shop name
                                </p>
                                <Input
                                    value={retailerDetails?.shopData?.shopname}
                                    onChange={(e) =>
                                        setRetailerDetails({
                                            ...retailerDetails,
                                            shopData: { ...retailerDetails.shopData, shopname: e.target.value }
                                        })
                                    }
                                    placeholder="Shop name"
                                    style={{ width: '100%' }}
                                />
                            </span>
                            <span style={{ width: '100%' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.shopData?.shoplocation ? '#00AA55' : '#696E7C',
                                            backgroundColor: retailerDetails?.shopData?.shoplocation ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    Address
                                </p>
                                <Input
                                    value={retailerDetails?.shopData?.shoplocation}
                                    onChange={(e) =>
                                        setRetailerDetails({
                                            ...retailerDetails,
                                            shopData: { ...retailerDetails.shopData, shoplocation: e.target.value }
                                        })
                                    }
                                    placeholder="Address"
                                    style={{ width: '100%' }}
                                />
                            </span>
                        </div>
                        <div
                            className="modal-body-content"
                            style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}
                        >
                            <span style={{ width: '100%', marginRight: '16px' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.shopData?.shoplat ? '#00AA55' : '#696E7C',
                                            backgroundColor: retailerDetails?.shopData?.shoplat ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    Latitude
                                </p>
                                <Input
                                    value={retailerDetails?.shopData?.shoplat}
                                    onChange={(e) =>
                                        setRetailerDetails({
                                            ...retailerDetails,
                                            shopData: { ...retailerDetails.shopData, shoplat: e.target.value }
                                        })
                                    }
                                    placeholder="Latitude"
                                    style={{ width: '100%' }}
                                />
                            </span>
                            <span style={{ width: '100%' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.shopData?.shoplng ? '#00AA55' : '#696E7C',
                                            backgroundColor: retailerDetails?.shopData?.shoplng ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    Longitude
                                </p>
                                <Input
                                    value={retailerDetails?.shopData?.shoplng}
                                    onChange={(e) =>
                                        setRetailerDetails({
                                            ...retailerDetails,
                                            shopData: { ...retailerDetails.shopData, shoplng: e.target.value }
                                        })
                                    }
                                    placeholder="34°S, 150°E"
                                    style={{ width: '100%' }}
                                />
                            </span>
                        </div>
                        <div
                            className="modal-body-content"
                            style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}
                        >
                            <span style={{ width: '100%', marginRight: '16px' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.shopData?.street ? '#00AA55' : '#696E7C',
                                            backgroundColor: retailerDetails?.shopData?.street ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    Street
                                </p>
                                <Input
                                    value={retailerDetails?.shopData?.street}
                                    onChange={(e) =>
                                        setRetailerDetails({
                                            ...retailerDetails,
                                            shopData: { ...retailerDetails.shopData, street: e.target.value }
                                        })
                                    }
                                    placeholder="Street"
                                    style={{ width: '100%' }}
                                />
                            </span>
                            <span style={{ width: '100%' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.shopData?.neighbourhood ? '#00AA55' : '#696E7C',
                                            backgroundColor: retailerDetails?.shopData?.neighbourhood ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    Neighborhood
                                </p>
                                <Input
                                    value={retailerDetails?.shopData?.neighbourhood}
                                    onChange={(e) =>
                                        setRetailerDetails({
                                            ...retailerDetails,
                                            shopData: { ...retailerDetails.shopData, neighbourhood: e.target.value }
                                        })
                                    }
                                    placeholder="Neighborhood"
                                    style={{ width: '100%' }}
                                />
                            </span>
                        </div>
                        <div
                            className="modal-body-content"
                            style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}
                        >
                            <span style={{ width: '100%', marginRight: '16px' }}>
                                <p style={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.shopData?.city ? '#00AA55' : '#696E7C',
                                            backgroundColor: retailerDetails?.shopData?.city ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    City
                                </p>
                                <Input
                                    value={retailerDetails?.shopData?.city}
                                    onChange={(e) =>
                                        setRetailerDetails({
                                            ...retailerDetails,
                                            shopData: { ...retailerDetails.shopData, city: e.target.value }
                                        })
                                    }
                                    placeholder="City"
                                    style={{ width: '100%' }}
                                />
                            </span>
                            <span style={{ width: '100%' }}>
                                <p style={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px' }}>Additional description</p>
                                <Input.TextArea
                                    value={retailerDetails?.shopData?.addressDescription}
                                    onChange={(e) =>
                                        setRetailerDetails({
                                            ...retailerDetails,
                                            shopData: { ...retailerDetails.shopData, addressDescription: e.target.value }
                                        })
                                    }
                                    placeholder="Additional description"
                                    style={{ width: '100%', resize: 'none' }}
                                />
                            </span>
                        </div>
                        <h3
                            style={{
                                marginBottom: '16px',
                                fontSize: '20px',
                                lineHeight: '30px',
                                fontWeight: 400,
                                fontFamily: 'Poppins'
                            }}
                        >
                            Retailer files
                        </h3>
                        <div>
                            <div style={{ width: '100%', marginBottom: '20px' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '16px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.shopPhoto ? '#00AA55' : '#000',
                                            backgroundColor: retailerDetails?.shopPhoto ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    Upload Photo of shop (Optional)
                                </p>
                                <Dragger {...props}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click to upload</p>
                                    <p className="ant-upload-hint">Maximum file size is 10mb</p>
                                </Dragger>
                            </div>
                            <div style={{ width: '100%', marginBottom: '20px' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '16px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.nationalId ? '#00AA55' : '#000',
                                            backgroundColor: retailerDetails?.nationalId ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    Upload National ID (Optional)
                                </p>
                                <Dragger {...idProps}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click to upload</p>
                                    <p className="ant-upload-hint">Maximum file size is 10mb</p>
                                </Dragger>
                            </div>

                            <div style={{ width: '100%', marginBottom: '20px' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '16px' }}>
                                    <span style={{ fontWeight: 400, color: '#4B6EFF' }}> Add Comments</span> (Optional)
                                </p>
                                <Input.TextArea
                                    value={retailerDetails.notes}
                                    onChange={(e) => setRetailerDetails({ ...retailerDetails, notes: e.target.value })}
                                    placeholder="Add Comments"
                                    style={{ width: '100%', height: '100px', resize: 'none' }}
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Button
                                    onClick={updateRetailer}
                                    style={{
                                        width: '100%',
                                        backgroundColor: '#FF740F',
                                        color: '#fff',
                                        borderRadius: '8px',
                                        border: 'none',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        fontWeight: 500,
                                        fontFamily: 'Poppins'
                                    }}
                                    disabled={
                                        !retailerDetails.firstName ||
                                        !retailerDetails.lastName ||
                                        !retailerDetails.shopData.shopname ||
                                        !retailerDetails.shopData.shoplocation ||
                                        !retailerDetails.shopData.shoplat ||
                                        !retailerDetails.shopData.shoplng ||
                                        !retailerDetails.shopData.street ||
                                        !retailerDetails.shopData.neighbourhood ||
                                        !retailerDetails.shopData.city ||
                                        !retailerDetails.shopData.addressDescription
                                    }
                                    loading={loadingUpdate}
                                >
                                    Complete Verification
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Index;
