// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import ConfirmationModal from 'components/OrderTable/ConfirmationModal';
import { useState } from 'react';
import RmaReceiptUpdateModal from 'components/RmaOrdersTable/RmaReceiptUpdateModal';
import dayjs from 'dayjs';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = (notifications) => {
    const theme = useTheme();

    const chipSX = {
        height: 36,
        padding: '0 6px',
        borderRadius: ' 200px'
        // cursor: 'pointer'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        height: 28
    };

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isReceiptUpdateOpen, setIsReceiptUpdateOpen] = useState(false);

    // confirmation modal
    const showConfirmationModal = () => {
        setIsConfirmationOpen(true);
    };

    const handleConfirmationOk = () => {
        setIsConfirmationOpen(false);
    };

    const handleConfirmationCancel = () => {
        setIsConfirmationOpen(false);
    };

    // receipt Update modal
    const showReceiptUpdateModal = () => {
        setIsReceiptUpdateOpen(true);
    };

    const handleReceiptUpdateOk = () => {
        setIsReceiptUpdateOpen(false);
    };

    const handleReceiptUpdateCancel = () => {
        setIsReceiptUpdateOpen(false);
    };

    return (
        <List
            sx={{
                width: '100%',
                // maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    // maxWidth: 300,
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {Array.isArray(notifications?.notifications) &&
                notifications?.notifications?.map((notification) => (
                    <div key={notification._id}>
                        <ListItemWrapper style={{ backgroundColor: notification.read === true ? '#fff' : '#DBE2FE' }}>
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            color: theme.palette.success.dark,
                                            backgroundColor: '#DBE2FF',
                                            border: 'none',
                                            borderColor: theme.palette.success.main
                                        }}
                                    >
                                        {' '}
                                        <NotificationsIcon
                                            sx={{
                                                color: '#4B6EFF'
                                            }}
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle1" sx={{ color: '#0A2938', fontWeight: '500' }}>
                                            {notification.title}
                                        </Typography>
                                    }
                                />
                                <ListItemSecondaryAction style={{ marginLeft: '20px' }}>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={12} sx={{ display: 'flex', alignContent: 'center' }}>
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                gutterBottom
                                                sx={{ color: '#7B808C', marginLeft: '20px' }}
                                            >
                                                {dayjs(notification.createdAt).format('DD MMM YYYY')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>

                            <Grid container direction="column" className="list-container">
                                <Grid item xs={12} sx={{ pb: 2 }}>
                                    <Typography variant="subtitle2" dangerouslySetInnerHTML={{ __html: notification.message }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item>
                                            <Chip
                                                label={notification?.country}
                                                sx={{
                                                    backgroundColor: 'transparent',
                                                    borderRadius: '8px',
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    color: '#4B6EFF',
                                                    width: 'fit-content',
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginRight: '10px',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        background: '#4B6EFF',
                                                        color: '#fff'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ListItemWrapper>
                        <Divider />
                    </div>
                ))}
        </List>
    );
};

export default NotificationList;
