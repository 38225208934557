/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    // Checkbox,
    // Divider,
    FormControl,
    // FormControlLabel,
    // FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

// project imports
import useScriptRef from 'hooks/useScriptRef';
// import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import Stack from '@mui/material/Stack';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiUrl from 'network';
// import City from 'views/city/City';

// ===========================|| FIREBASE - REGISTER ||=========================== //
const useStyles = makeStyles(() => ({
    color: {
        color: '#FF740F'
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    }
}));

const AuthGarageRegister = () => {
    const theme = useTheme();
    const classes = useStyles();
    const scriptedRef = useScriptRef();
    const history = useNavigate();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    // const customization = useSelector((state) => state.customization);
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [country, setCountry] = useState('');
    const [cityId, setcityId] = useState('');
    const [cityData, setCityData] = useState([]);
    const [dob, setdob] = useState(new Date());
    const [contactPhone, setcontactPhone] = useState('');
    const [location, setlocation] = useState('');
    const [strength, setStrength] = useState(0);
    const [loading, setLoading] = useState(false);
    const [level, setLevel] = useState();
    const [accountType, setaccountType] = useState('');
    const [countries, setCountries] = useState([]);
    // console.log(loading);

    // const googleHandler = async () => {
    //     console.error('Register');
    // };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSignupChange = (newValue) => {
        setdob(newValue);
    };

    const handleRoleChange = (event) => {
        setaccountType(event.target.value);
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };
    const retrieveAllCity = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.CityFilterUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCityData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const filterCountry = (event) => {
        const newFilter = countries.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            retrieveAllCity(option?._id);
        });
    };
    const handleCityChange = (event) => {
        setcityId(event.target.value);
    };
    const retrieveAllActiveCountry = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        changePassword('123456');
        retrieveAllActiveCountry();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            firstName,
            lastName,
            location,
            contactPhone,
            dob,
            accountType,
            country,
            email,
            password,
            cityId
        };
        fetch(ApiUrl.GarageMainSignupUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);

                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/otpverification');
                        }
                    }, 5000);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />
                <br />

                {/* <Grid item xs={12}>
                    <AnimateButton>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={googleHandler}
                            size="large"
                            sx={{
                                color: 'grey.700',
                                backgroundColor: theme.palette.grey[50],
                                borderColor: theme.palette.grey[100]
                            }}
                        >
                            <Box sx={{ mr: { xs: 1, sm: 2, width: 20 } }}>
                                <img src={Google} alt="google" width={16} height={16} style={{ marginRight: matchDownSM ? 8 : 16 }} />
                            </Box>
                            Sign up with Google
                        </Button>
                    </AnimateButton>
                </Grid> */}
                {/* <Grid item xs={12}>
                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                        <Button
                            variant="outlined"
                            sx={{
                                cursor: 'unset',
                                m: 2,
                                py: 0.5,
                                px: 7,
                                borderColor: `${theme.palette.grey[100]} !important`,
                                color: `${theme.palette.grey[900]}!important`,
                                fontWeight: 500,
                                borderRadius: `${customization.borderRadius}px`
                            }}
                            disableRipple
                            disabled
                        >
                            OR
                        </Button>
                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                    </Box>
                </Grid> */}
                {/* <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Sign up with Email address</Typography>
                    </Box>
                </Grid> */}
            </Grid>

            <Formik
                // initialValues={{
                //     email: '',
                //     password: '',
                //     submit: null
                // }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                // onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                //     try {
                //         if (scriptedRef.current) {
                //             setStatus({ success: true });
                //             setSubmitting(false);
                //         }
                //     } catch (err) {
                //         console.error(err);
                //         if (scriptedRef.current) {
                //             setStatus({ success: false });
                //             setErrors({ submit: err.message });
                //             setSubmitting(false);
                //         }
                //     }
                // }}
            >
                {({ handleBlur }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    margin="normal"
                                    name="firstName"
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => setfirstName(e.target.value)}
                                    sx={{ ...theme.typography.customInput }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    margin="normal"
                                    name="lastName"
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => setlastName(e.target.value)}
                                    sx={{ ...theme.typography.customInput }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={accountType}
                                label="roles"
                                onChange={handleRoleChange}
                            >
                                <MenuItem value="Body">Select an option</MenuItem>
                                <MenuItem value="620e4ae34d1fa525b854c081">RelationShip Manager</MenuItem>
                                <MenuItem value="620e4c33db526fe88a23b39f">Price Sourcing</MenuItem>
                                <MenuItem value="6238c5bdf978bc24fe108481">Fulfillment</MenuItem>
                                <MenuItem value="6238d12ff978bc24fe108599">Customer Care</MenuItem>
                                <MenuItem value="6238d13cf978bc24fe10859d">DataEntry</MenuItem>
                                <MenuItem value="631dcf8c21bead14c9a940cb">Driver/Rider</MenuItem>
                            </Select>
                        </FormControl>

                        <br />
                        <br />

                        <Grid container spacing={matchDownSM ? 0 : 4}>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="country-select-demo"
                                    autoHighlight
                                    value={country}
                                    onChange={(event, newValue) => {
                                        setCountry(newValue);
                                        filterCountry(newValue);
                                    }}
                                    options={countries.map((option) => option.name)}
                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                    renderInput={(params) => <TextField {...params} label="Choose a country" />}
                                />
                            </Grid>
                            <br />
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Choose a City</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={cityId}
                                        label="City"
                                        onChange={handleCityChange}
                                    >
                                        {cityData.map((attr) => (
                                            <MenuItem key={attr._id} value={attr._id}>
                                                {attr?.city ?? ''}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br />

                        <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-register">Email Address </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-register"
                                type="email"
                                value={email}
                                // value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={(e) => setEmail(e.target.value)}
                                inputProps={{}}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-password-register">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-register"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                // value={values.password}
                                name="password"
                                label="Password"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    changePassword(e.target.value);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                inputProps={{}}
                            />
                        </FormControl>

                        {strength !== 0 && (
                            <FormControl fullWidth>
                                <Box sx={{ mb: 2 }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <Box
                                                style={{ backgroundColor: level?.color }}
                                                sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" fontSize="0.75rem">
                                                {level?.label}
                                            </Typography>
                                            <Typography variant="subtitle1" fontSize="0.75rem">
                                                (Min. 8 characters with a letter, number and a special characters)
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </FormControl>
                        )}
                        <br />
                        <Grid item xs={12} sm={12}>
                            <TextField
                                autoComplete="off"
                                name="contactPhone"
                                type="number"
                                variant="outlined"
                                fullWidth
                                id="contactPhone"
                                value={contactPhone}
                                label="Contact Phone"
                                onChange={(e) => setcontactPhone(e.target.value)}
                            />
                        </Grid>
                        <br />
                        <Grid item xs={12} sm={12}>
                            <TextField
                                autoComplete="off"
                                name="location"
                                variant="outlined"
                                fullWidth
                                id="location"
                                value={location}
                                label="Location"
                                onChange={(e) => setlocation(e.target.value)}
                            />
                        </Grid>

                        <br />
                        <Grid item xs={12} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        label="Date Of Birth"
                                        inputFormat="MM/dd/yyyy"
                                        value={dob}
                                        onChange={handleSignupChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Grid>

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={
                                        !firstName ||
                                        !lastName ||
                                        !email ||
                                        !password ||
                                        !accountType ||
                                        !country ||
                                        !contactPhone ||
                                        !dob ||
                                        !location ||
                                        !cityId
                                    }
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className={classes.backgroundColor}
                                >
                                    Sign up
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default AuthGarageRegister;
