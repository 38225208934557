import { Button, Collapse, Select, Table, DatePicker, Tag, Modal } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import ApiUrl from '../../../network';
import { toast } from 'react-toastify';
import { decrypt, encrypt } from '../../../utils/encrypt';
import dayjs from 'dayjs';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const [optionSelected, setOptionSelected] = useState('credit-tracker');
    const { RangePicker } = DatePicker;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [approvalData, setApprovalData] = useState([]);
    const [rmacc, setRmacc] = useState('');
    const [customer, setCustomer] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [rmaccOptions, setRmaccOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [id, setId] = useState('');

    const [approvalModal, setApprovalModal] = useState(false);

    const [totalepl, setTotalEpl] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 25; // Fixed page size as per API

    // eslint-disable-next-line consistent-return
    const fetchAllData = async (data, page = 1) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Fetch Credit Data
            const urlParams = new URLSearchParams(window.location.search);
            if (data) {
                if (data.startDate) urlParams.append('startDate', startDate);
                if (data.endDate) urlParams.append('endDate', endDate);
                if (data.customer) urlParams.append('customer', customer);
                if (data.rmacc) urlParams.append('rmacc', rmacc);
            }

            urlParams.append('page', page);
            urlParams.append('pageSize', pageSize);

            const creditUrl = `${ApiUrl.BaseUrl}/epl/credittrackerdata?${urlParams.toString()}`;
            const creditResponse = await fetch(creditUrl, config);
            const creditData = await creditResponse.json();
            if (creditData.message === 'success') {
                const decryptedCreditData = JSON.parse(decrypt(creditData.data, keyResult, ivResult));
                setTotalEpl(creditData.totalepl);
                setData(decryptedCreditData);
            } else {
                toast.error(creditData.message);
            }

            // Fetch RMACC Data
            // don't fetch if there is data or already fetched
            if (rmaccOptions.length > 0 && customerOptions.length > 0) {
                return;
            }
            const rmaccUrl = `${ApiUrl.BaseUrl}/epl/eplcustomersrmacc`;
            const rmaccResponse = await fetch(rmaccUrl, config);
            const rmaccData = await rmaccResponse.json();
            if (rmaccData.message === 'Success') {
                const decryptedRmaccData = JSON.parse(decrypt(rmaccData.data, keyResult, ivResult));
                setRmaccOptions(decryptedRmaccData.rmcc);
                setCustomerOptions(decryptedRmaccData.customer);
            } else {
                toast.error(rmaccData.message);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    // eslint-disable-next-line camelcase
    const fetch_payment_data = async (data, page = 1) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Fetch Credit Data
            const urlParams = new URLSearchParams(window.location.search);
            if (data) {
                if (data.startDate) urlParams.append('startDate', startDate);
                if (data.endDate) urlParams.append('endDate', endDate);
                if (data.customer) urlParams.append('customer', customer);
                if (data.rmacc) urlParams.append('rmacc', rmacc);
            }
            urlParams.append('page', page);
            urlParams.append('pageSize', pageSize);

            const paymentUrl = `${ApiUrl.BaseUrl}/epl/creditpaymentdata?${urlParams.toString()}`;
            const paymentResponse = await fetch(paymentUrl, config);
            const payment = await paymentResponse.json();
            if (payment.message === 'success') {
                const decryptedPayment = JSON.parse(decrypt(payment.data, keyResult, ivResult));
                setTotalEpl(payment.totalepl);
                setPaymentData(decryptedPayment);
            } else {
                toast.error(payment.message);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    // eslint-disable-next-line camelcase
    const fetch_approval_data = async (data, page = 1) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Fetch Credit Data
            const urlParams = new URLSearchParams(window.location.search);
            if (data) {
                if (data.startDate) urlParams.append('startDate', startDate);
                if (data.endDate) urlParams.append('endDate', endDate);
                if (data.customer) urlParams.append('customer', customer);
                if (data.rmacc) urlParams.append('rmacc', rmacc);
            }
            urlParams.append('page', page);
            urlParams.append('pageSize', pageSize);

            const paymentUrl = `${ApiUrl.BaseUrl}/epl/financeeplcollection?${urlParams.toString()}`;
            const paymentResponse = await fetch(paymentUrl, config);
            const payment = await paymentResponse.json();
            if (payment.message === 'success') {
                const decryptedPayment = JSON.parse(decrypt(payment.data, keyResult, ivResult));
                setTotalEpl(payment.totalepl);
                setApprovalData(decryptedPayment);
            } else {
                toast.error(payment.message);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        optionSelected === 'credit-tracker'
            ? fetchAllData({}, currentPage)
            : optionSelected === 'credit-payment'
            ? fetch_payment_data({}, currentPage)
            : fetch_approval_data({}, currentPage);
    }, [optionSelected, currentPage]);

    // options for select
    const customerOptionHandler = useMemo(() => {
        return customerOptions.map((item) => ({
            label: item.partnerName,
            value: item._id
        }));
    }, [customerOptions]);

    const rmaccOptionHandler = useMemo(() => {
        return rmaccOptions.map((item) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item._id
        }));
    }, [rmaccOptions]);

    const handleDateChange = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };

    const applyFilter = () => {
        const data = {
            startDate,
            endDate,
            customer,
            rmacc
        };
        // eslint-disable-next-line no-unused-expressions
        optionSelected === 'credit-tracker' ? fetchAllData(data) : fetch_payment_data(data);
    };

    const resetFilter = () => {
        setStartDate('');
        setEndDate('');
        setRmacc('');
        setCustomer('');
        // eslint-disable-next-line no-unused-expressions
        optionSelected === 'credit-tracker' ? fetchAllData() : fetch_payment_data();
    };

    // eslint-disable-next-line array-callback-return
    const creditTrackerSource = data.map((item) => {
        // format date
        const date = dayjs(item.date).format('D MMMM YYYY');
        const dueDate = dayjs(item.dueDate).format('D MMMM YYYY');
        const maxDueDate = dayjs(item.maxDueDate).format('D MMMM YYYY');
        const createdAt = dayjs(item.createdAt).format('D MMMM YYYY');
        const updatedAt = dayjs(item.updatedAt).format('D MMMM YYYY');

        let placedFor = `${`${item?.eplrequest?.placedfor?.firstName} ${item?.eplrequest?.placedfor?.lastName}`}`;
        // eslint-disable-next-line camelcase
        let rma_cc = `${`${item?.rmacc?.firstName} ${item?.rmacc?.lastName}`}`;
        if (placedFor === 'undefined undefined') {
            placedFor = 'N/A';
        }

        // eslint-disable-next-line camelcase
        if (rma_cc === 'undefined undefined' || rma_cc === 'undefined' || rma_cc === 'null null' || rma_cc === 'null') {
            // eslint-disable-next-line camelcase
            rma_cc = 'N/A';
        }

        return {
            key: item._id,
            date,
            creditno: item.creditno,
            customer_name: `${item?.customergh?.partnerName || 'N/A'} ${item?.customergh?.partnerContact || 'N/A'}`,
            tier: item.tier,
            principalAmt: item.principalAmt,
            amount_due: `${item.currency?.name} ${item.amtDue}`,
            actual_timeframe: item.actualTimeFrame,
            due_date: dueDate,
            rma_cc,
            place_for: placedFor,
            max_due_date: maxDueDate,
            maxTimeFrame: item.maxTimeFrame,
            createdBy: `${item?.createdBy?.firstName || 'N/A'} ${item?.createdBy?.lastName || 'N/A'}`,
            created_at: createdAt,
            updatedBy: `${item?.updatedBy?.firstName} ${item?.updatedBy?.lastName}`,
            updated_at: updatedAt
        };
    });
    const paymentSource = paymentData.map((item) => {
        // format date
        const date = dayjs(item.date).format('D MMMM YYYY');
        const dueDate = dayjs(item?.creditTrackerId?.dueDate).format('D MMMM YYYY');
        const maxDueDate = dayjs(item?.maxDueDate).format('D MMMM YYYY');
        const createdAt = dayjs(item.createdAt).format('D MMMM YYYY');
        const updatedAt = dayjs(item.updatedAt).format('D MMMM YYYY');
        const paymentDate = dayjs(item.payment_date).format('D MMMM YYYY');

        return {
            key: item._id,
            date,
            credit_no: item?.creditno,
            creditstatus: item.creditstatus,
            customer_name: `${item?.customer?.partnerName} ${item?.customer?.partnerContact}`,
            tier: item?.tier,
            actual_timeframe: item?.actualTimeFrame,
            due_date: dueDate,
            max_due_date: maxDueDate,
            fees: item.fees,
            rma_cc: `${item?.rmacc?.firstName} ${item?.rmacc?.lastName}`,
            amount_due: `${item.currency?.name} ${item?.amtDue}`,
            amount_paid: `${item.currency?.name} ${item.amtPaid}`,
            amount_balance: `${item.currency?.name} ${item.amountBalance}`,
            recon_amt: `${item.currency?.name} ${item.reconAmt}`,
            payment_date: paymentDate,
            default: item.defaultstatus,
            createdBy: `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`,
            created_at: createdAt,
            updatedBy: `${item?.updatedBy?.firstName} ${item?.updatedBy?.lastName}`,
            updated_at: updatedAt
        };
    });
    const approvalSource = approvalData.map((item) => {
        // format date
        const paymentDate = dayjs(item.payment_date).format('D MMMM YYYY');

        return {
            key: item._id,
            credit_no: item?.creditTracker?.creditno,
            status: item.status,
            customer_name: `${item?.customer?.partnerName} ${item?.customer?.partnerContact}`,
            rma_cc: `${item?.rmacc?.firstName} ${item?.rmacc?.lastName}`,
            amount_due: `${item.currency?.name} ${item?.amtDue || 0}`,
            amount_paid: `${item.currency?.name} ${item.amountPaid || 0}`,
            payment_date: paymentDate
        };
    });

    const trackercolumns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Credit no:',
            dataIndex: 'creditno',
            key: 'creditno'
        },
        {
            title: 'Customer name',
            dataIndex: 'customer_name',
            key: 'customer_name'
        },
        {
            title: 'Tier',
            dataIndex: 'tier',
            key: 'Tier'
        },
        {
            title: 'Principal amount',
            dataIndex: 'principalAmt',
            key: 'principalAmt'
        },
        {
            title: 'Amount due',
            dataIndex: 'amount_due',
            key: 'amount_due'
        },
        {
            title: 'Actual timeframe',
            dataIndex: 'actual_timeframe',
            key: 'actual_timeframe'
        },
        {
            title: 'Due date',
            dataIndex: 'due_date',
            key: 'due_date'
        },
        {
            title: 'RMA/CC',
            dataIndex: 'rma_cc',
            key: 'rma_cc'
        },
        {
            title: 'Place For',
            dataIndex: 'place_for',
            key: 'place_for'
        },
        {
            title: 'Max due date',
            dataIndex: 'max_due_date',
            key: 'max_due_date'
        },
        {
            title: 'Max timeframe (Days)',
            dataIndex: 'maxTimeFrame',
            key: 'maxTimeFrame'
        },
        {
            title: 'Aprroved by',
            dataIndex: 'createdBy',
            key: 'createdBy'
        },
        {
            title: 'Date created',
            dataIndex: 'created_at',
            key: 'created_at'
        },
        {
            title: 'Updated by',
            dataIndex: 'updatedBy',
            key: 'updatedBy'
        },
        {
            title: 'Date updated',
            dataIndex: 'updated_at',
            key: 'updated_at'
        }
    ];
    const paymentcolumns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Credit no:',
            dataIndex: 'credit_no',
            key: 'credit_no'
        },
        {
            title: 'Credit status',
            dataIndex: 'creditstatus',
            key: 'creditstatus'
        },
        {
            title: 'Customer name',
            dataIndex: 'customer_name',
            key: 'customer_name'
        },
        {
            title: 'Tier',
            dataIndex: 'tier',
            key: 'Tier'
        },
        {
            title: 'Actual timeframe',
            dataIndex: 'actual_timeframe',
            key: 'actual_timeframe'
        },
        {
            title: 'Due date',
            dataIndex: 'due_date',
            key: 'due_date'
        },
        {
            title: 'Max due date',
            dataIndex: 'max_due_date',
            key: 'max_due_date'
        },
        {
            title: 'Fees',
            dataIndex: 'fees',
            key: 'fees'
        },
        {
            title: 'RMA/CC',
            dataIndex: 'rma_cc',
            key: 'rma_cc'
        },
        {
            title: 'Amount due',
            dataIndex: 'amount_due',
            key: 'amount_due'
        },
        {
            title: 'Amount paid',
            dataIndex: 'amount_paid',
            key: 'amount_paid'
        },
        {
            title: 'Amount balance',
            dataIndex: 'amount_balance',
            key: 'amount_balance'
        },
        {
            title: 'Reconciled amount',
            dataIndex: 'recon_amt',
            key: 'recon_amt'
        },
        {
            title: 'Payment date',
            dataIndex: 'payment_date',
            key: 'payment_date'
        },
        {
            title: 'Default',
            dataIndex: 'default',
            key: 'default'
        },
        {
            title: 'Created by',
            dataIndex: 'createdBy',
            key: 'createdBy'
        },
        {
            title: 'Date created',
            dataIndex: 'created_at',
            key: 'created_at'
        },
        {
            title: 'Updated by',
            dataIndex: 'updatedBy',
            key: 'updatedBy'
        },
        {
            title: 'Date updated',
            dataIndex: 'updated_at',
            key: 'updated_at'
        }
    ];
    const approvalcolumns = [
        {
            title: 'Payment date',
            dataIndex: 'payment_date',
            key: 'payment_date'
        },
        {
            title: 'Credit no:',
            dataIndex: 'credit_no',
            key: 'credit_no'
        },
        {
            title: 'Customer name',
            dataIndex: 'customer_name',
            key: 'customer_name'
        },
        {
            title: 'RMA/CC',
            dataIndex: 'rma_cc',
            key: 'rma_cc'
        },
        {
            title: 'Amount due',
            dataIndex: 'amount_due',
            key: 'amount_due'
        },
        {
            title: 'Amount paid',
            dataIndex: 'amount_paid',
            key: 'amount_paid'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <div>
                    <Tag
                        style={{ borderRadius: '10px' }}
                        color={
                            record?.status?.toLowerCase() === 'approved'
                                ? 'green'
                                : record?.status?.toLowerCase() === 'rejected'
                                ? 'red'
                                : 'blue'
                        }
                    >
                        {record?.status}
                    </Tag>
                </div>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <div>
                    <Button
                        size="small"
                        onClick={() => {
                            setId(record.key);
                            setApprovalModal(true);
                        }}
                        style={{
                            backgroundColor: 'transparent',
                            color: '#FF740F',
                            border: '1px solid #FF740F'
                        }}
                    >
                        Approve / Reject
                    </Button>
                </div>
            )
        }
    ];

    const exportToExcel = (type) => {
        let data = [];
        let columns = [];
        if (type === 'credit-tracker') {
            data = creditTrackerSource;
            columns = trackercolumns;
        } else {
            data = paymentSource;
            columns = paymentcolumns;
        }

        const csvData = [];
        const header = columns.map((item) => item.title);
        csvData.push(header);

        data.forEach((item) => {
            const row = [];
            columns.forEach((column) => {
                row.push(item[column.dataIndex]);
            });
            csvData.push(row);
        });

        const csv = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', `${type}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchAllData({}, page);
    };

    const handlePaymentPageChange = (page) => {
        setCurrentPage(page);
        fetch_payment_data({}, page);
    };

    // eslint-disable-next-line camelcase
    const approval_reject_handler = (status) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const datas = {
                id,
                status
            };
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            fetch(`${ApiUrl.BaseUrl}/epl/approveeplcollections`, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.message === 'success') {
                        toast.success(data.message);
                        setApprovalModal(false);
                        fetch_approval_data({}, currentPage);
                    } else {
                        toast.error(data.message);
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        } catch (error) {
            toast.error(error.message);
        }
    };
    return (
        <div className="easy-pay-container">
            <div className="header">
                <div>
                    <h1 className="font-30">Easy pay later</h1>
                    <p style={{ color: '#565C6B', fontWeight: 'normal' }}>Keep track of all credit transactions</p>
                </div>
                {optionSelected === 'credit-tracker' ? (
                    <div className="header-actions">
                        {/* <Button */}
                        {/*    onClick={() => (window.location.href = '/epl/credit-add')} */}
                        {/*    style={{ backgroundColor: '#FF740F', color: '#fff' }} */}
                        {/* > */}
                        {/*    Add credit */}
                        {/* </Button> */}
                        <Button onClick={() => exportToExcel('credit-tracker')} style={{ backgroundColor: '#FF740F', color: '#fff' }}>
                            Export credit
                        </Button>
                        <Button
                            onClick={() => (window.location.href = '/epl/easy-pay-summary')}
                            style={{ backgroundColor: '#0A2938', color: '#fff' }}
                        >
                            View summary
                        </Button>
                    </div>
                ) : (
                    <div className="header-actions">
                        <Button onClick={() => exportToExcel('credit-payment')} style={{ backgroundColor: '#FF740F', color: '#fff' }}>
                            Export payment
                        </Button>
                        <Button
                            onClick={() => (window.location.href = '/epl/add-payment')}
                            style={{ backgroundColor: '#0A2938', color: '#fff' }}
                        >
                            Add payment
                        </Button>
                    </div>
                )}
            </div>
            <hr className="divider" />
            <div className="content">
                <div className="content-actions">
                    <Button
                        onClick={() => {
                            setStartDate('');
                            setEndDate('');
                            setRmacc('');
                            setCustomer('');
                            setOptionSelected('credit-tracker');
                        }}
                        style={{
                            backgroundColor: `${optionSelected === 'credit-tracker' ? '#ECEDEF' : '#f5f5f5'}`,
                            color: '#233E4C',
                            border: `1px solid ${optionSelected === 'credit-tracker' ? '#A1A5AD' : '#E7EAEB'}`
                        }}
                    >
                        Credit tracker
                    </Button>
                    <Button
                        onClick={() => {
                            setStartDate('');
                            setEndDate('');
                            setRmacc('');
                            setCustomer('');
                            setOptionSelected('credit-payment');
                        }}
                        style={{
                            backgroundColor: `${optionSelected === 'credit-payment' ? '#ECEDEF' : '#f5f5f5'}`,
                            color: '#233E4C',
                            border: `1px solid ${optionSelected === 'credit-payment' ? '#A1A5AD' : '#E7EAEB'}`
                        }}
                    >
                        Payment
                    </Button>
                    <Button
                        onClick={() => {
                            setStartDate('');
                            setEndDate('');
                            setRmacc('');
                            setCustomer('');
                            setOptionSelected('approve-payment');
                        }}
                        style={{
                            backgroundColor: `${optionSelected === 'approve-payment' ? '#ECEDEF' : '#f5f5f5'}`,
                            color: '#233E4C',
                            border: `1px solid ${optionSelected === 'approve-payment' ? '#A1A5AD' : '#E7EAEB'}`
                        }}
                    >
                        Approvals
                    </Button>
                </div>
                {optionSelected === 'credit-tracker' ? (
                    <div>
                        <Collapse accordion>
                            <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                                <div className="batchFilters">
                                    <div className="mr-2">
                                        <Select
                                            style={{
                                                width: 300
                                            }}
                                            className="filterInput"
                                            placeholder="Search Customers"
                                            options={customerOptionHandler}
                                            onChange={(value) => setCustomer(value)}
                                            value={customer || null}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                    </div>
                                    <div className="mr-2">
                                        <Select
                                            style={{
                                                width: 300
                                            }}
                                            className="filterInput"
                                            placeholder="Search RMA/CC"
                                            options={rmaccOptionHandler}
                                            onChange={(value) => setRmacc(value)}
                                            value={rmacc || null}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                    </div>
                                    <div>
                                        <RangePicker
                                            onChange={(date, dateString) => {
                                                handleDateChange(date, dateString);
                                            }}
                                            value={
                                                startDate && endDate ? [dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')] : null
                                            }
                                            format="YYYY-MM-DD"
                                            className="datePicker filterInput"
                                        />
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <Button
                                            style={{
                                                color: '#FF740F',
                                                border: '1px solid #FF740F'
                                            }}
                                            loading={loading}
                                            onClick={() => applyFilter()}
                                        >
                                            Apply filter
                                        </Button>
                                        {(startDate && endDate) || rmacc || customer ? (
                                            <Button
                                                onClick={() => {
                                                    resetFilter();
                                                }}
                                                style={{
                                                    color: '#FF740F',
                                                    border: '1px solid #FF740F'
                                                }}
                                                className="ml-2"
                                            >
                                                Reset filter
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>
                            </Collapse.Panel>
                        </Collapse>
                        <div className="table-responsive">
                            <Table
                                className="table"
                                loading={loading}
                                columns={trackercolumns}
                                dataSource={creditTrackerSource}
                                scroll={{ x: true }}
                                pagination={{
                                    current: currentPage,
                                    pageSize,
                                    total: totalepl,
                                    showSizeChanger: false,
                                    showTotal: (total) => `Total ${total} credit transactions`,
                                    onChange: handlePageChange
                                }}
                            />
                        </div>
                    </div>
                ) : optionSelected === 'credit-payment' ? (
                    <div>
                        <Collapse accordion>
                            <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                                <div className="batchFilters">
                                    <div className="mr-2">
                                        <Select
                                            className="filterInput"
                                            style={{
                                                width: 300
                                            }}
                                            placeholder="Search Customers"
                                            options={customerOptionHandler}
                                            onChange={(value) => setCustomer(value)}
                                            value={customer || null}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                    </div>
                                    <div className="mr-2">
                                        <Select
                                            className="filterInput"
                                            style={{
                                                width: 300
                                            }}
                                            placeholder="Search RMA/CC"
                                            options={rmaccOptionHandler}
                                            onChange={(value) => setRmacc(value)}
                                            value={rmacc || null}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                    </div>
                                    <div>
                                        <RangePicker
                                            onChange={(date, dateString) => {
                                                handleDateChange(date, dateString);
                                            }}
                                            value={
                                                startDate && endDate ? [dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')] : null
                                            }
                                            format="YYYY-MM-DD"
                                            className="datePicker filterInput"
                                        />
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <Button
                                            style={{
                                                color: '#FF740F',
                                                border: '1px solid #FF740F'
                                            }}
                                            loading={loading}
                                            onClick={() => applyFilter()}
                                        >
                                            Apply filter
                                        </Button>
                                        {(startDate && endDate) || rmacc || customer ? (
                                            <Button
                                                onClick={() => {
                                                    resetFilter();
                                                }}
                                                style={{
                                                    color: '#FF740F',
                                                    border: '1px solid #FF740F'
                                                }}
                                                className="ml-2"
                                            >
                                                Reset filter
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>
                            </Collapse.Panel>
                        </Collapse>
                        <div className="table-responsive">
                            <Table
                                className="table"
                                loading={loading}
                                columns={paymentcolumns}
                                dataSource={paymentSource}
                                scroll={{ x: true }}
                                pagination={{
                                    current: currentPage,
                                    pageSize,
                                    total: totalepl,
                                    showSizeChanger: false,
                                    showTotal: (total) => `Total ${total} payment transactions`,
                                    onChange: handlePaymentPageChange
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <Collapse accordion>
                            <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                                <div className="batchFilters">
                                    <div className="mr-2">
                                        <Select
                                            className="filterInput"
                                            style={{
                                                width: 300
                                            }}
                                            placeholder="Search Customers"
                                            options={customerOptionHandler}
                                            onChange={(value) => setCustomer(value)}
                                            value={customer || null}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                    </div>
                                    <div className="mr-2">
                                        <Select
                                            className="filterInput"
                                            style={{
                                                width: 300
                                            }}
                                            placeholder="Search RMA/CC"
                                            options={rmaccOptionHandler}
                                            onChange={(value) => setRmacc(value)}
                                            value={rmacc || null}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                    </div>
                                    <div>
                                        <RangePicker
                                            onChange={(date, dateString) => {
                                                handleDateChange(date, dateString);
                                            }}
                                            value={
                                                startDate && endDate ? [dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')] : null
                                            }
                                            format="YYYY-MM-DD"
                                            className="datePicker filterInput"
                                        />
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <Button
                                            style={{
                                                color: '#FF740F',
                                                border: '1px solid #FF740F'
                                            }}
                                            loading={loading}
                                            onClick={() => applyFilter()}
                                        >
                                            Apply filter
                                        </Button>
                                        {(startDate && endDate) || rmacc || customer ? (
                                            <Button
                                                onClick={() => {
                                                    resetFilter();
                                                }}
                                                style={{
                                                    color: '#FF740F',
                                                    border: '1px solid #FF740F'
                                                }}
                                                className="ml-2"
                                            >
                                                Reset filter
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>
                            </Collapse.Panel>
                        </Collapse>
                        <div className="table-responsive">
                            <Table
                                className="table"
                                loading={loading}
                                columns={approvalcolumns}
                                dataSource={approvalSource}
                                scroll={{ x: true }}
                                pagination={{
                                    current: currentPage,
                                    pageSize,
                                    total: totalepl,
                                    showSizeChanger: false,
                                    showTotal: (total) => `Total ${total} payment transactions`,
                                    onChange: handlePaymentPageChange
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <Modal title="Approve Payment or Reject" open={approvalModal} onCancel={() => setApprovalModal(false)} footer={null}>
                <div>
                    <div
                        className="mb-4 mt-4 text-center"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '100px'
                        }}
                    >
                        <p>
                            Are you sure you want to <span style={{ color: 'red' }}>Reject</span> or{' '}
                            <span style={{ color: 'green' }}>Approve</span> this payment?
                        </p>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                        <Button
                            style={{
                                backgroundColor: 'transparent',
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                width: '48%'
                            }}
                            onClick={() => {
                                const status = false;
                                approval_reject_handler(status);
                            }}
                        >
                            Reject
                        </Button>
                        <Button
                            style={{
                                backgroundColor: '#FF740F',
                                color: '#fff',
                                border: '1px solid #FF740F',
                                width: '48%'
                            }}
                            onClick={() => {
                                const status = true;
                                approval_reject_handler(status);
                            }}
                        >
                            Approve
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Index;
